import { DateTime } from "luxon"
import { Instance, SnapshotOut, applySnapshot, types } from "mobx-state-tree"
import {
  teamsAddTeam,
  teamsAddTeamMember,
  teamsDeleteTeam,
  teamsDeleteTeamMember,
  teamsGetYourTeams,
  teamsUpdateTeam,
  userDeleteFunds,
  userGetFunds,
  userGetYourProfile,
  userSubmitFeedback,
  userUpdateProfile,
} from "../../services/client"
import { withSetPropAction } from "../withSetPropAction"
import { ProfileModel } from "./Profile"
import { TeamMemberModel, TeamModel } from "./Teams"

export const UserModel = types
  .model("User")
  .props({
    accessToken: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    picture: types.maybeNull(types.string),
    loggedIn: types.optional(types.boolean, false),
    userId: types.maybe(types.string),
    teams: types.optional(types.array(TeamModel), []),
    profile: types.maybe(ProfileModel),
    proofOfFunds: types.maybeNull(types.boolean),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    reset() {
      applySnapshot(store, {})
    },
  }))
  .actions((store) => ({
    async checkFunds({ include_url = false }: { include_url?: boolean }) {
      const result: { error: string | null; errorDetails?: string | null; url?: string | null } = {
        error: null,
      }

      const { data, error } = await userGetFunds({ query: { include_url } })

      if (error) {
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e) => e.msg).join(", ")
          : error.detail
        result.error = "Failed to get proof of funds info"
        store.setProp("proofOfFunds", false)
      } else if (data) {
        result.url = data.url
        store.setProp("proofOfFunds", true)
      } else {
        store.setProp("proofOfFunds", false)
      }

      return result
    },
    async deleteFundsDoc() {
      const result: { error: string | null; errorDetails?: string | null } = { error: null }
      if (store.userId && store.proofOfFunds) {
        const { error } = await userDeleteFunds()

        if (error) {
          result.errorDetails = Array.isArray(error.detail)
            ? error.detail.map((e) => e.msg).join(", ")
            : error.detail
          result.error = "Failed to delete funds info"
        } else {
          store.setProp("proofOfFunds", false)
        }
      }

      return result
    },
    async fetchTeam() {
      const result: { error: string | null; errorDetails?: string | null } = { error: null }
      if (!store.userId) return { error: "No User Id" }

      const teamRes = await teamsGetYourTeams({ query: { limit: 20 } })
      if (teamRes.error) {
        result.error = "Failed to fetch teams"

        if (Array.isArray(teamRes.error.detail)) {
          result.errorDetails = teamRes.error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = teamRes.error.detail
        }
      }

      if (teamRes.data) {
        const teams = []

        for (const entry of teamRes.data) {
          teams.push(
            TeamModel.create({
              ...entry,
              created_at: DateTime.fromISO(entry.created_at, { zone: "utc" }).toJSDate(),
              members: entry.members.map((m) => {
                return TeamMemberModel.create({
                  ...m,
                  created_at: DateTime.fromISO(entry.created_at, { zone: "utc" }).toJSDate(),
                })
              }),
            }),
          )
        }

        store.setProp("teams", teams)
      }

      return result
    },
    async fetchProfile() {
      const result: { error: string | null; errorDetails?: string | null } = { error: null }
      if (!store.userId) return { error: "No user ID found" }
      if (!store.loggedIn) return { error: "Not logged in" }

      const profileRes = await userGetYourProfile()

      if (profileRes.error) {
        result.error = "Failed to fetch profile"

        if (Array.isArray(profileRes.error.detail)) {
          result.errorDetails = profileRes.error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = profileRes.error.detail
        }
      }

      if (profileRes.data) {
        store.setProp("profile", profileRes.data)
      }

      return result
    },
  }))
  .actions((store) => ({
    async addTeam({ name, description }: { name: string; description: string }) {
      const result: { error: string | null; errorDetails?: string | null } = { error: null }

      const teamRes = await teamsAddTeam({ body: { name, description } })

      if (teamRes.error) {
        result.error = "Failed to add team"

        if (Array.isArray(teamRes.error.detail)) {
          result.errorDetails = teamRes.error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = teamRes.error.detail
        }
      } else {
        await store.fetchTeam()
      }

      return result
    },
    async updateTeam({
      team,
      name,
      description,
    }: {
      team: Team
      name: string
      description: string
    }) {
      const result: { error: string | null; errorDetails?: string | null } = { error: null }

      const teamRes = await teamsUpdateTeam({
        path: { id: team.id },
        body: { name, description },
      })

      if (teamRes.error) {
        result.error = "Failed to update team"

        if (Array.isArray(teamRes.error.detail)) {
          result.errorDetails = teamRes.error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = teamRes.error.detail
        }
      } else {
        team.setProp("name", name)
        team.setProp("description", description)
      }

      return result
    },
    async addTeamMember({ team_id, email }: { team_id: string; email: string }) {
      const result: { error: string | null; errorDetails?: string | null } = { error: null }

      const teamRes = await teamsAddTeamMember({
        path: { id: team_id },
        body: { email },
      })

      if (teamRes.error) {
        result.error = "Failed to add user"

        if (Array.isArray(teamRes.error.detail)) {
          result.errorDetails = teamRes.error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = teamRes.error.detail
        }
      } else {
        await store.fetchTeam()
      }

      return result
    },
    async removeTeamMember({ team_id, memberId }: { team_id: string; memberId: string }) {
      const result: { error: string | null; errorDetails?: string | null } = { error: null }

      const teamRes = await teamsDeleteTeamMember({
        path: { id: team_id, member_id: memberId },
      })

      if (teamRes.error) {
        result.error = "Failed to delete team member"

        if (Array.isArray(teamRes.error.detail)) {
          result.errorDetails = teamRes.error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = teamRes.error.detail
        }
      } else {
        await store.fetchTeam()
      }

      return result
    },
    async deleteTeam({ team_id }: { team_id: string }) {
      const result: { error: string | null; errorDetails?: string | null } = { error: null }

      const teamRes = await teamsDeleteTeam({
        path: { id: team_id },
      })

      if (teamRes.error) {
        result.error = "Failed to delete team"

        if (Array.isArray(teamRes.error.detail)) {
          result.errorDetails = teamRes.error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = teamRes.error.detail
        }
      } else {
        await store.fetchTeam()
      }

      return result
    },
    async submitFeedback({ feedback }: { feedback: string }) {
      const result: { error: string | null; errorDetails?: string | null } = { error: null }

      const feedbackRes = await userSubmitFeedback({
        body: { feedback },
      })

      if (feedbackRes.error) {
        result.error = "Failed to submit feedback"

        if (Array.isArray(feedbackRes.error.detail)) {
          result.errorDetails = feedbackRes.error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = feedbackRes.error.detail
        }
      }

      return result
    },
    async updateProfile({
      name,
      pic,
      agent_license,
    }: {
      name?: string
      pic?: Blob | null
      agent_license?: number | null
    }) {
      const result: { error: string | null; errorDetails?: string | null } = { error: null }
      if (!store.userId) return { error: "No user ID found" }

      const updateRes = await userUpdateProfile({ body: { name, pic, agent_license } })

      if (updateRes.error) {
        result.error = "Failed to update profile"

        if (Array.isArray(updateRes.error.detail)) {
          result.errorDetails = updateRes.error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = updateRes.error.detail
        }
      } else {
        store.fetchProfile()
      }

      return result
    },
  }))
  .views((store) => ({
    teamById(teamId: string) {
      return store.teams.find((t) => t.id === teamId)
    },
    get team_ids() {
      return store.teams.map((t) => t.id)
    },
    get teamMemberIds() {
      const memberIds: string[] = []

      for (const team of store.teams) {
        for (const member of team.members) {
          if (!memberIds.includes(member.user_id)) {
            memberIds.push(member.user_id)
          }
        }
      }

      return memberIds
    },
    get profileName() {
      return store.profile?.name ? store.profile?.name : store.name ? store.name : "You"
    },
  }))

export interface User extends Instance<typeof UserModel> {}
export interface UserSnapshot extends SnapshotOut<typeof UserModel> {}

export interface Team extends Instance<typeof TeamModel> {}
export interface TeamSnapshot extends SnapshotOut<typeof TeamModel> {}
