import { VariantType, useSnackbar } from 'notistack'
import { useCallback, useState } from 'react'

/**
 * A hook that can be used to manage the state of API calls.
 * Loading, Refreshing, Error, Success
 */
export function useApiCallState() {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState<boolean>(false)
  const [refreshing, setRefreshing] = useState<boolean>(false)
  const [confirmDeleteId, setConfirmDeleteId] = useState<
    string | number | null
  >(null)

  const setMsg = useCallback(
    (msg: string, variant: VariantType = 'info') => {
      enqueueSnackbar(msg, { variant })
    },
    [enqueueSnackbar]
  )

  return {
    loading,
    setLoading,
    refreshing,
    setRefreshing,
    confirmDeleteId,
    setConfirmDeleteId,
    setMsg,
  }
}
