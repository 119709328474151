import { useTheme } from '../../theme'

const pinSVGHole =
  'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z'

// https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
export const useMarkerImage = () => {
  const theme = useTheme()

  return {
    path: pinSVGHole,
    fillOpacity: 1,
    fillColor: theme.palette.primary.main,
    strokeWeight: 2,
    strokeColor: theme.palette.background.default,
    scale: 2,
  }
}
