import { DateTime } from "luxon"
import { Instance, SnapshotOut, types } from "mobx-state-tree"
import {
  chatsGetChatMessages,
  chatsSendChatMessage,
  userBulkFetchUserProfiles,
} from "../../../shared/services"
import { ProfileModel } from "../Account/Profile"
import { withSetPropAction } from "../withSetPropAction"
import { MsgModel } from "./Msg"

export const ChatStoreModel = types
  .model("ChatStore")
  .props({
    messages: types.optional(types.array(MsgModel), []),
    hasEarlierMessages: false,
    isLoadingEarlier: false,
    profiles: types.optional(types.array(ProfileModel), []),
    activeBuyerId: types.maybeNull(types.string),
    activeReactionMsgId: types.maybeNull(types.string),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    async getProfiles({ userIds, refresh = false }: { userIds: string[]; refresh?: boolean }) {
      const result: {
        errorDetails?: string | null
        profiles: { [key: string]: { name?: string; pic?: string; agent_license?: number | null } }
      } = { profiles: {} }

      const newUserIds: string[] = []

      if (refresh) {
        newUserIds.push(...userIds)
      } else {
        for (const userId of userIds) {
          const profile = store.profiles.find((p) => p.user_id === userId)
          if (profile) {
            result.profiles[userId] = {
              name: profile.name || undefined,
              pic: profile.pic || undefined,
            }
          } else {
            newUserIds.push(userId)
          }
        }
      }

      if (newUserIds.length > 0) {
        const { data, error } = await userBulkFetchUserProfiles({ body: { ids: newUserIds } })

        if (error) {
          result.errorDetails = Array.isArray(error.detail)
            ? error.detail.map((e) => e.msg).join(", ")
            : error.detail
        } else if (data) {
          const newRecords = data.map((record) => {
            result.profiles[record.user_id] = {
              name: record.name || undefined,
              pic: record.pic || undefined,
              agent_license: record.agent_license,
            }
            return ProfileModel.create(record)
          })

          if (newRecords.length > 0) {
            if (refresh) {
              store.setProp("profiles", newRecords)
            } else {
              store.setProp("profiles", [...store.profiles, ...newRecords])
            }

            return result
          }
        }
      }

      return result
    },
  }))
  .actions((store) => ({
    async refreshProfiles() {
      const userIds: string[] = []

      for (const prof of store.profiles) {
        if (prof.user_id) {
          userIds.push(prof.user_id)
        }
      }

      await store.getProfiles({ userIds, refresh: true })

      return null
    },
    async fetchMessages({
      listing_id,
      message_id,
      limit = 20,
      skip,
    }: {
      listing_id?: string
      message_id?: string
      limit?: number
      skip?: number
    }) {
      const result: { error: string | null; errorDetails?: string | null } = {
        error: "Failed to fetch chats",
      }

      const { error, data } = await chatsGetChatMessages({
        query: { listing_id, message_id, limit, skip },
      })

      if (error) {
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e) => e.msg).join(", ")
          : error.detail

        return result
      } else {
        const fetchedMessages = data.map((record) => {
          return MsgModel.create({
            id: record.id,
            createdAt: DateTime.fromISO(record.created_at, { zone: "utc" }).toJSDate(),
            text: record.text,
            user: {
              id: record.user.user_id,
              name: record.user.name,
              avatar: record.user.pic ?? undefined,
            },
            listing_id: record.listing_id,
            buyer_user: record.buyer_user
              ? {
                  id: record.buyer_user.user_id,
                  name: record.buyer_user.name,
                  avatar: record.buyer_user.pic ?? undefined,
                }
              : null,
            type: record.type,
            attachments: record.attachments,
            reactions: record.reactions?.map((r) => ({
              ...r,
              created_at: DateTime.fromISO(record.created_at, { zone: "utc" }).toJSDate(),
            })),
          })
        })

        const allMessages = [
          ...store.messages.filter((m) => !fetchedMessages.find((c) => c.id === m.id)),
          ...fetchedMessages,
        ].sort((a, b) => (a.createdAt === b.createdAt ? 0 : a.createdAt > b.createdAt ? 1 : -1))

        store.setProp("messages", allMessages)
        store.setProp("hasEarlierMessages", fetchedMessages.length === limit)

        return { error: null }
      }
    },
  }))
  .actions(() => ({
    async sendMessage({
      listing_id,
      text,
      buyer_user_id,
    }: {
      listing_id: string
      text: string
      buyer_user_id?: string
    }) {
      const result: { error: string | null; errorDetails?: string | null } = {
        error: "Failed to send message",
      }

      const { error } = await chatsSendChatMessage({ body: { listing_id, text, buyer_user_id } })
      if (error) {
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e) => e.msg).join(", ")
          : error.detail

        return result
      } else {
        return { error: null }
      }
    },
  }))
  .views((store) => ({
    messageBuyers({ listing_id }: { listing_id: string }) {
      const buyers: {
        [key: string]: {
          buyerId: string
          name?: string
          avatar?: string
          latestMsg: Date
          preview: string
        }
      } = {}

      const sortedMessages = store.messages
        .filter((m) => m.listing_id === listing_id)
        .sort((a, b) => (a.createdAt === b.createdAt ? 0 : a.createdAt > b.createdAt ? 1 : -1))

      for (const entry of sortedMessages) {
        if (entry.buyer_user) {
          buyers[entry.buyer_user.id] = {
            buyerId: entry.buyer_user.id,
            name: entry.buyer_user.name || undefined,
            avatar: entry.buyer_user.avatar,
            latestMsg: entry.createdAt,
            preview: entry.text.length <= 75 ? entry.text : `${entry.text.slice(0, 75)}...`,
          }
        }
      }

      return Object.values(buyers)
    },
    listingBuyerMessages({
      listing_id,
      buyer_user_id,
      web = false,
    }: {
      listing_id: string
      buyer_user_id: string
      web?: boolean
    }) {
      // On the web chat we need to revers the order of the messages
      const ascending = web ? -1 : 1
      const descending = web ? 1 : -1

      return store.messages
        .filter((m) => m.listing_id === listing_id && m.buyer_user?.id === buyer_user_id)
        .sort((a, b) =>
          a.createdAt === b.createdAt ? 0 : a.createdAt > b.createdAt ? descending : ascending,
        )
    },
    profileByUserId(userId: string) {
      return store.profiles.find((p) => p.user_id === userId)
    },
    msgById(id: string) {
      return store.messages.find((m) => m.id === id)
    },
  }))

export interface ChatStore extends Instance<typeof ChatStoreModel> {}
export interface ChatStoreSnapshot extends SnapshotOut<typeof ChatStoreModel> {}
