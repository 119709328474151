import { useEffect, useState } from 'react'
import { Notification } from '../../../shared/models'
import { useStores } from '../models'
import { useNotifyNav } from './useNotifications'

export function useBrowserNotify() {
  const rootStore = useStores()
  const { notificationStore } = rootStore
  const notifyNav = useNotifyNav()
  const [browserNotifications, setBrowserNotifications] = useState<
    { notify?: Notification; browserNotification: globalThis.Notification }[]
  >([])
  const realtimeNotification: Notification | undefined =
    notificationStore.realtimeNotification

  useEffect(() => {
    async function sendNotification() {
      if (!realtimeNotification) return

      realtimeNotification.setProp('realtime', false)

      // Check if browser notification access is allowed
      if (!('Notification' in window)) return
      const permission = await window.Notification.requestPermission()
      if (permission !== 'granted') return

      const browserNotification = new window.Notification(
        realtimeNotification.title,
        {
          body: realtimeNotification.body || undefined,
        }
      )
      if (!browserNotification) return

      setBrowserNotifications(current => [
        ...current,
        { notify: realtimeNotification, browserNotification },
      ])

      browserNotification.addEventListener('click', async () => {
        const { error, errorDetails } =
          await notificationStore.ackNotifications({
            ids: [realtimeNotification.id],
          })

        if (error) {
          console.error(errorDetails)
        }

        browserNotification.close()

        notifyNav({
          screen: realtimeNotification.screen || undefined,
          active_id: realtimeNotification.active_id || undefined,
        })
      })
    }

    if (realtimeNotification) {
      sendNotification()
    }
  }, [notificationStore, notifyNav, realtimeNotification])

  useEffect(() => {
    // Close the browser notification if it has been acked somewhere else
    if (browserNotifications.length > 0) {
      const closedIds: string[] = []
      for (const entry of browserNotifications) {
        if (entry.notify && entry.notify?.ack) {
          entry.browserNotification.close()
          closedIds.push(entry.notify.id)
        }
      }

      if (closedIds.length > 0) {
        setBrowserNotifications(
          browserNotifications.filter(
            n => n.notify?.id && !closedIds.includes(n.notify?.id)
          )
        )
      }
    }
  }, [notificationStore, notifyNav, browserNotifications])

  return null
}
