import { Box, SxProps, Theme } from '@mui/material'
import { APIProvider, Map } from '@vis.gl/react-google-maps'
import { useState } from 'react'
import { Listing } from '../../../../shared/models'
import { getRegionForCoordinates } from '../../../../shared/utils'
import config from '../../config'
import { useStores } from '../../models'
import { useTheme } from '../../theme'
import { MapMarker } from './MapMarker'
import { darkMap } from './darkMap'

interface MapProps {
  listings: Listing[]
  defaultZoom?: number
  listingDetailsMap?: boolean
  sx?: SxProps<Theme>
  defaultDisable?: boolean
  isFullScreen?: boolean
}

export function GoogleMap({
  listings,
  defaultZoom = 10,
  listingDetailsMap = false,
  sx,
  defaultDisable,
  isFullScreen = false,
}: MapProps) {
  const { listingStore } = useStores()
  const [mapActive, setMapActive] = useState(false)
  const theme = useTheme()

  if (theme.palette.mode === 'light') {
    import('./map.css')
  } else {
    import('./mapDark.css')
  }

  const coordinates: {
    latitude: number
    longitude: number
  }[] = []

  for (const listing of listings) {
    if (listing.latitude && listing.longitude) {
      coordinates.push({
        latitude: listing.latitude,
        longitude: listing.longitude,
      })
    }
  }

  const region = getRegionForCoordinates(coordinates)
  const map = (
    <APIProvider apiKey={config.googleMapsApiKey}>
      <Map
        onClick={() => listingStore.setProp('activeListingId', null)}
        defaultCenter={region}
        defaultZoom={defaultZoom}
        styles={theme.palette.mode === 'dark' ? darkMap : undefined}
        gestureHandling={'greedy'}
        disableDefaultUI={!listingDetailsMap}
        style={
          defaultDisable
            ? {
                pointerEvents: mapActive ? 'all' : 'none',
              }
            : {}
        }
      >
        {listings?.map(
          listing =>
            listing.longitude &&
            listing.latitude && (
              <MapMarker
                key={listing.id}
                listing={listing}
                listingDetailsMap={listingDetailsMap}
                isFullScreen={isFullScreen}
                position={{
                  lng: listing.longitude,
                  lat: listing.latitude,
                }}
              />
            )
        )}
      </Map>
    </APIProvider>
  )

  return defaultDisable ? (
    <Box component='section' sx={sx} onClick={() => setMapActive(true)}>
      {map}
    </Box>
  ) : (
    map
  )
}
