import { Alert, Box, Button, CircularProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { links } from '../../../../shared/data'
import { MsgStatus } from '../../../../shared/models'
import { userAddFundsProof } from '../../../../shared/services'
import { McIcon, VisuallyHiddenInput } from '../../Components'
import { useApiCallState } from '../../hooks'
import { useStores } from '../../models'
import { useTheme } from '../../theme'

export const FundsUpload: React.FC = observer(() => {
  const { accountStore } = useStores()
  const { loading, setLoading, setMsg } = useApiCallState()
  const theme = useTheme()
  const userId = accountStore.user.userId

  return (
    <Box>
      <Alert severity='info' variant='filled' sx={{ mb: 2 }}>
        <a
          href={links.proofOfFunds}
          target='_blank'
          rel='noopener noreferrer'
          style={{ color: theme.palette.info.contrastText }}
        >
          Proof of funds
        </a>{' '}
        is required to submit an offer or schedule a tour.{' '}
        <Box>
          Please upload a <b>mortgage preapproval</b> or an{' '}
          <b>official bank statement</b> PDF
        </Box>
      </Alert>

      <Box sx={{ mb: theme.spacing(1) }}>
        <Button
          component='label'
          color='error'
          role={undefined}
          variant='contained'
          tabIndex={-1}
          startIcon={<McIcon path='cloud-upload' />}
          sx={{ width: '100%' }}
          disabled={loading}
        >
          Proof of Funds
          {loading && (
            <CircularProgress size={24} sx={{ ml: theme.spacing(1) }} />
          )}
          <VisuallyHiddenInput
            type='file'
            accept='.pdf'
            multiple={false}
            onChange={async event => {
              if (
                userId &&
                event.target.files &&
                event.target.files?.length > 0
              ) {
                setLoading(true)
                const { error } = await userAddFundsProof({
                  body: { new_file: event.target.files[0] },
                })

                if (error) {
                  const errorDetails = Array.isArray(error.detail)
                    ? error.detail.map(e => e.msg).join(', ')
                    : error.detail

                  setMsg(
                    errorDetails ?? 'Failed to upload funds',
                    MsgStatus.ERROR
                  )
                } else {
                  accountStore.user.setProp('proofOfFunds', true)
                  setMsg('Proof of funds uploaded', MsgStatus.SUCCESS)
                }
                setLoading(false)
              }
            }}
          />
        </Button>
      </Box>
    </Box>
  )
})
