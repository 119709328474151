import Icon from '@mdi/react'
import { IconProps } from '@mdi/react/dist/IconProps'
import { getIconPath, IconMapping } from './getIconPath'

interface McIconProps extends IconProps {
  path: IconMapping
  size?: number
}

export function McIcon(props: McIconProps) {
  return (
    <Icon {...props} path={getIconPath(props.path)} size={props.size || 1} />
  )
}
