import { Avatar, Box, Button, CircularProgress, TextField } from '@mui/material'
import imageCompression from 'browser-image-compression'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { McIcon, VisuallyHiddenInput } from '../../Components'
import config from '../../config'
import { useApiCallState } from '../../hooks'
import { useStores } from '../../models'
import { useTheme } from '../../theme'

interface IFormInput {
  name: string
  agent_license: string
}

export const Profile: React.FC = observer(() => {
  const { accountStore } = useStores()
  const { loading, setLoading, setMsg } = useApiCallState()
  const theme = useTheme()
  const { control, handleSubmit, formState } = useForm<IFormInput>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const profileName = accountStore.user.profileName
  const profilePic = accountStore.user.profile?.pic
  const userId = accountStore.user.userId
  const userName = accountStore.user.profileName
  const agent_license_default = accountStore.user.profile?.agent_license ?? ''

  const onSubmit = async (data: IFormInput) => {
    setLoading(true)

    let agent_license: number = 0
    const agentLicenseTrimmed = data.agent_license.trim()
    if (agentLicenseTrimmed) {
      const agentLicenseNumber = parseInt(agentLicenseTrimmed)
      if (!isNaN(agentLicenseNumber)) {
        agent_license = agentLicenseNumber
      }
    }

    const profileUpdate = await accountStore.user.updateProfile({
      name: data.name,
      agent_license,
    })

    if (profileUpdate.error) {
      setMsg(profileUpdate.error, 'error')
    } else {
      setMsg('Profile updated', 'success')
    }

    setLoading(false)
  }

  return (
    <Box sx={{ mb: theme.spacing(1) }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          component='label'
          role={undefined}
          variant='text'
          tabIndex={-1}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={120} sx={{ ml: theme.spacing(1) }} />
          ) : (
            <Avatar
              alt={profileName}
              src={profilePic ?? config.assets.noImage}
              sx={{ width: 120, height: 120 }}
            />
          )}
          <VisuallyHiddenInput
            type='file'
            accept='image/*'
            multiple={false}
            onChange={async event => {
              if (
                userId &&
                event.target.files &&
                event.target.files?.length > 0
              ) {
                setLoading(true)
                let pic = event.target.files[0]

                try {
                  const compressedPic = await imageCompression(
                    event.target.files[0],
                    {
                      maxSizeMB: 1,
                      maxWidthOrHeight: 1920,
                      useWebWorker: true,
                    }
                  )
                  pic = compressedPic
                } catch (error) {
                  console.error(error)
                }

                const profileUpdate = await accountStore.user.updateProfile({
                  pic,
                })

                if (profileUpdate.error) {
                  setMsg(profileUpdate.error, 'error')
                }

                setLoading(false)
              }
            }}
          />
        </Button>
      </Box>

      <Box sx={{ mt: theme.spacing(4) }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label='Name'
                sx={{ width: '100%' }}
                variant='filled'
                className='mb-4'
                error={!!fieldState.error}
              />
            )}
            name='name'
            control={control}
            defaultValue={userName}
            rules={{ required: true, maxLength: 50 }}
            disabled={loading}
          />

          <Controller
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label='Agent License Number'
                sx={{ width: '100%' }}
                variant='filled'
                type='number'
                error={!!fieldState.error}
              />
            )}
            name='agent_license'
            control={control}
            defaultValue={`${agent_license_default}`}
            rules={{ maxLength: 20 }}
            disabled={loading}
          />

          <Box
            sx={{
              mt: theme.spacing(2),
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant='contained'
              color='success'
              startIcon={<McIcon path='content-save' />}
              disabled={
                !Object.values(formState.touchedFields).some(v => !!v) ||
                !formState.isValid ||
                loading
              }
              type='submit'
            >
              Save
              {loading && (
                <CircularProgress size={24} sx={{ ml: theme.spacing(1) }} />
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
})
