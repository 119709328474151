import { Map, ViewAgenda } from '@mui/icons-material'
import { Container, Fab, Skeleton, useMediaQuery } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { GoogleMap } from '../../Components'
import { useApiCallState } from '../../hooks'
import useWindowDimensions from '../../hooks/useWindowDimensions.ts'
import { useStores } from '../../models'
import { useTheme } from '../../theme.tsx'
import { ListingCard } from './ListingCard.tsx'

export const Listings = observer(() => {
  const [mapHidden, setMapHidden] = useState(true)
  const { loading, setLoading, setMsg } = useApiCallState()
  const { listingStore, accountStore } = useStores()
  const { height } = useWindowDimensions()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  const isSmallHeader = useMediaQuery(theme.breakpoints.down('sm'))
  const headerHeight = isSmallHeader ? 56 : 64
  const heightMinusHeader = height - headerHeight
  const sortedListings = listingStore.sortedListings
  const userId = accountStore.user.userId

  useEffect(() => {
    document.title = 'Listings'
  }, [])

  useEffect(() => {
    async function getListings() {
      setLoading(true)

      const fetchResult = await listingStore.fetchListings({})
      if (fetchResult && fetchResult.error) {
        console.error(fetchResult.errorDetails || fetchResult.error)
        setMsg(fetchResult.error, 'error')
      }

      setLoading(false)
    }

    // Reset active listing Id when loading the listings page
    listingStore.setProp('activeListingId', null)

    getListings()
  }, [setLoading, setMsg, listingStore, userId])

  return (
    <Grid container direction={{ xs: 'column', md: 'row' }}>
      <Grid
        size={{ sm: 12, lg: 5 }}
        sx={{ display: { lg: 'block', xs: mapHidden ? 'none' : 'block' } }}
      >
        <div
          style={{
            height: heightMinusHeader,
          }}
        >
          {loading ? (
            <Skeleton height='100%' variant='rectangular' />
          ) : (
            listingStore.listings.length > 0 && (
              <GoogleMap
                listings={listingStore.listings}
                isFullScreen={isSmallScreen}
              />
            )
          )}
        </div>
      </Grid>

      <Fab
        variant='extended'
        color='primary'
        sx={{
          position: 'fixed',
          bottom: 0,
          left: '50%',
          transform: 'translate(-50%, -50%)',
          margin: '0 auto',
          display: { lg: 'none' },
        }}
        onClick={() => setMapHidden(current => !current)}
      >
        {mapHidden ? (
          <>
            <Map sx={{ mr: 1 }} />
            Map
          </>
        ) : (
          <>
            <ViewAgenda sx={{ mr: 1 }} />
            Listings
          </>
        )}
      </Fab>

      <Grid size={{ sm: 12, lg: 7 }}>
        <Container
          maxWidth={false}
          sx={{
            height: heightMinusHeader,
            overflowY: 'scroll',
            display: { lg: 'block', xs: mapHidden ? 'block' : 'none' },
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ mt: theme.spacing(2), mb: theme.spacing(10) }}
          >
            {loading &&
              [1, 2, 3, 4].map(x => (
                <Grid key={x} size={{ xs: 12, md: 6 }}>
                  <Skeleton variant='rectangular' height={400} />
                </Grid>
              ))}

            {!loading &&
              sortedListings.map(listing => (
                <Grid
                  size={{ xs: 12, md: sortedListings.length > 1 ? 6 : 12 }}
                  key={listing.id}
                >
                  <ListingCard
                    listing={listing}
                    raiseOnActive={true}
                    activeScrollIntoView={!isSmallScreen}
                  />
                </Grid>
              ))}
          </Grid>
        </Container>
      </Grid>
    </Grid>
  )
})
