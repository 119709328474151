import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Skeleton,
  Stack,
  useMediaQuery,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { McIcon } from '../../../Components'
import { useApiCallState } from '../../../hooks'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { useStores } from '../../../models'
import { useTheme } from '../../../theme'
import { Message } from './Message'

const MAX_MESSAGE_LENGTH = 300
const MAX_MESSAGE_NEW_LINES = 5

interface ChatProps {
  listing_id: string
  ownerName?: string
  team_name?: string | null
  ownerPic?: string
  onClose: () => void
}

export const Chat: React.FC<ChatProps> = observer(
  ({ listing_id, ownerName, team_name, ownerPic, onClose }) => {
    const { chatStore, accountStore, fileStore } = useStores()
    const [text, setText] = useState('')
    const { loading, setLoading, setMsg } = useApiCallState()
    const messageBottomRef = useRef<HTMLDivElement | null>(null)
    const theme = useTheme()
    const { height } = useWindowDimensions()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const userId = accountStore.user.userId

    if (!userId) return null
    const messages = chatStore.listingBuyerMessages({
      listing_id,
      buyer_user_id: userId,
      web: true,
    })
    const messagesLength = messages.length

    // Initial message fetch
    useEffect(() => {
      async function getMessages() {
        setLoading(true)
        const { error } = await chatStore.fetchMessages({
          listing_id,
        })
        setLoading(false)

        if (error) {
          setMsg(error, 'error')
        }
      }

      getMessages()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listing_id])

    // Handle scroll to bottom
    useEffect(() => {
      if (!loading && messageBottomRef.current && messagesLength > 0) {
        messageBottomRef.current.scrollIntoView({ behavior: 'instant' })
      }
    }, [loading, messagesLength])

    async function sendMessage() {
      if (!userId) return

      setLoading(true)
      const { error } = await chatStore.sendMessage({
        listing_id,
        text,
        buyer_user_id: userId,
      })

      if (error) {
        setMsg(error, 'error')
      } else {
        await Promise.all([
          chatStore.fetchMessages({ listing_id, limit: 10 }),
          fileStore.fetchFiles({ listing_id, limit: 10 }),
        ])

        setText('')
      }

      setLoading(false)
    }

    return (
      <Card
        sx={{
          mt: theme.spacing(1),
          alignItems: 'center',
          position: 'fixed',
          bottom: 0,
          right: isSmall ? 0 : theme.spacing(2),
          width: isSmall ? '100%' : 400,
          zIndex: 9999,
        }}
        variant='elevation'
        elevation={24}
      >
        <CardHeader
          avatar={
            ownerPic ? (
              <Avatar alt={ownerName || 'Seller'} src={ownerPic} />
            ) : (
              <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                <McIcon path='account-circle' />
              </Avatar>
            )
          }
          title={ownerName || 'Seller'}
          titleTypographyProps={{ variant: 'subtitle1' }}
          subheader={team_name}
          action={
            loading ? (
              <CircularProgress
                size={30}
                sx={{ mt: theme.spacing(1), mr: theme.spacing(1) }}
              />
            ) : (
              <IconButton onClick={onClose} sx={{ mr: theme.spacing(1) }}>
                <McIcon path='close' />
              </IconButton>
            )
          }
        />

        <Divider />

        <CardContent
          sx={{
            overflowY: 'auto',
            height: isSmall ? height * 0.3 : 400,
          }}
        >
          <Stack
            direction='column'
            justifyContent='flex-start'
            alignItems='stretch'
            spacing={1}
          >
            {loading &&
              messages.length === 0 &&
              [...Array(5).keys()].map(i => (
                <Skeleton
                  key={i}
                  height={66}
                  variant='rectangular'
                  sx={{
                    borderRadius: 3,
                    width: '80%',
                    alignSelf: i % 2 ? 'flex-end' : 'flex-start',
                  }}
                />
              ))}

            {messages.map(message => (
              <Message key={message.id} userId={userId} message={message} />
            ))}
          </Stack>
          <div
            id='msg-bottom'
            style={{ float: 'left', clear: 'both' }}
            ref={messageBottomRef}
          />
        </CardContent>

        <Divider />

        <Paper
          component='form'
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            borderRadius: 0,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder='New Message'
            inputProps={{ 'aria-label': 'new message' }}
            autoFocus
            multiline
            maxRows={2}
            size='small'
            value={text}
            onChange={e => {
              const text = e.target.value
              const newText = text
                .split('\n')
                .slice(0, MAX_MESSAGE_NEW_LINES)
                .join('\n')

              if (newText.length >= MAX_MESSAGE_LENGTH) {
                setText(newText.slice(0, MAX_MESSAGE_LENGTH))
              } else {
                setText(newText)
              }
            }}
          />
          <IconButton
            color='primary'
            sx={{ p: '10px' }}
            aria-label='send'
            disabled={!text}
            onClick={sendMessage}
          >
            <McIcon path='send' />
          </IconButton>
        </Paper>
      </Card>
    )
  }
)
