import { useNavigate, useSearchParams } from 'react-router-dom'
import { NotificationData } from '../../../shared/models'
import { getVanityAddress } from '../../../shared/utils'
import { useStores } from '../models'

export function useNotifyNav() {
  const { listingStore, offerStore, tourStore, accountStore, fileStore } =
    useStores()
  const navigate = useNavigate()
  const setSearchParams = useSearchParams()[1]
  const userId = accountStore.user.userId

  async function fetchListingAddress(id: string) {
    let listingAddress: string | null = null

    // Check if we already have the listing data
    let listing = listingStore.listingById(id)
    if (listing) {
      listingAddress = listing.address
    } else {
      // Otherwise fetch the listing
      const { error } = await listingStore.fetchListings({
        listing_id: id,
      })

      if (error) {
        console.error(error)
      } else {
        listing = listingStore.listingById(id)
        if (listing) {
          listingAddress = listing.address
        }
      }
    }

    return listingAddress
  }

  async function notifyNav({ screen, active_id }: NotificationData) {
    if (!userId) return

    if (
      screen &&
      active_id &&
      ['ListingOffers', 'ListingTours', 'Chat', 'files'].includes(screen)
    ) {
      const listingAddress = await fetchListingAddress(active_id)

      if (listingAddress) {
        if (screen === 'ListingOffers') {
          offerStore.fetchOffers({ listing_id: active_id })
        } else if (screen === 'ListingTours') {
          tourStore.fetchTours({ listing_id: active_id })
        } else if (screen === 'files') {
          fileStore.fetchFiles({ listing_id: active_id })
        }

        navigate(`/listings/${getVanityAddress(listingAddress)}/${active_id}`)

        // Open chat
        if (screen === 'Chat') {
          setSearchParams({ chat: '1' })
        }
      }
    }
  }

  return notifyNav
}
