import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { withSetPropAction } from "../withSetPropAction"

export interface ICounter {
  price: number
  earnest: number
  dueDilligence: number
  comments?: string
}

export const CounterModel = types
  .model("Counter")
  .props({
    price: types.number,
    earnest: types.number,
    due_dilligence: types.number,
    comments: types.maybeNull(types.string),
    user_id: types.string,
  })
  .actions(withSetPropAction)

export interface Counter extends Instance<typeof CounterModel> {}
export interface CounterSnapshot extends SnapshotOut<typeof CounterModel> {}
