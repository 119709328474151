import {
  Avatar,
  Box,
  Chip,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'

import 'react-image-gallery/styles/css/image-gallery.css'
import { Listing } from '../../../../shared/models'
import { McIcon } from '../../Components'
import { useTheme } from '../../theme'
import './imageGallery.css'
import { useListingAttributes } from './useListingAttributes'

interface HeaderAttributesProps {
  listing: Listing
  ownerName?: string
  ownerPic?: string
  card?: boolean
}

export const HeaderAttributes: React.FC<HeaderAttributesProps> = observer(
  ({ listing, ownerName, ownerPic, card = false }) => {
    const theme = useTheme()
    const attributes = useListingAttributes(listing)
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const statusIconParms = listing.statusIconParams

    const statusChip = (
      <Chip
        label={listing.status}
        color={statusIconParms.publicColor}
        avatar={
          <McIcon
            path={statusIconParms.icon}
            color={theme.palette.background.default}
            size={0.8}
          />
        }
        size='small'
        sx={{ ml: 1, height: 20 }}
      />
    )

    return (
      <Box sx={{ mb: theme.spacing(2) }}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: card ? undefined : 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            <Typography variant={card || isSmall ? 'h5' : 'h4'} component='div'>
              {attributes.price}
            </Typography>

            {!card && statusChip}
          </Box>

          {card && statusChip}

          {!card && ownerName && (
            <Tooltip title='Seller'>
              <Box className='flex flex-row flex-wrap items-center content-start'>
                {ownerPic ? (
                  <Avatar alt={ownerName} src={ownerPic} sx={{ mr: 1 }} />
                ) : (
                  <Avatar
                    sx={{ bgcolor: theme.palette.primary.contrastText, mr: 1 }}
                  >
                    <McIcon path='account-circle' />
                  </Avatar>
                )}
                <Box>
                  <Typography>{ownerName}</Typography>
                  {listing.show_team && listing.team_name && (
                    <Typography className={isSmall ? 'text-xs' : 'text-sm'}>
                      {listing.team_name}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Tooltip>
          )}
        </Box>

        <Box sx={$attributeContainer}>
          {attributes.headline.map(attr => (
            <Box sx={$attribute} key={attr.title}>
              {attr.icon}{' '}
              <Typography variant={card || isSmall ? 'body1' : 'h6'}>
                {attr.title}
              </Typography>
            </Box>
          ))}
        </Box>

        <Typography variant='body2'>
          {listing.address.replace(', USA', '')}
        </Typography>
      </Box>
    )
  }
)

const $attribute: SxProps<Theme> = {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  marginRight: 1,
  '& svg': {
    m: 1,
  },
}

const $attributeContainer: SxProps<Theme> = {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  '& svg': {
    m: 1,
  },
}
