export const listingPhases = {
  team: {
    id: "team",
    title: "Team",
    headline: "Are you working with a team, partner or business?",
    icon: "account-group",
    multi: false,
    items: [],
  },
  type: {
    id: "type",
    title: "Home Type",
    headline: "Which of these best describes your place?",
    icon: "home",
    multi: false,
    items: [
      {
        id: "single_family",
        label: "Single Family",
        icon: "home",
      },
      {
        id: "multi_family",
        label: "Multi Family",
        icon: "home-group",
      },
      {
        id: "condo",
        label: "Condo",
        icon: "home-city",
      },
      {
        id: "townhouse",
        label: "Townhouse",
        icon: "home-modern",
      },
      {
        id: "mobile_manufactured",
        label: "Mobile / Manufactured",
        icon: "home-variant",
      },
    ],
  },
  details: {
    id: "details",
    title: "Details",
    headline: "Share some basic details about your place",
    icon: "information",
    multi: false,
    items: [],
  },
  rooms: {
    id: "rooms",
    title: "Rooms",
    headline: "How many rooms does your place have?",
    icon: "floor-plan",
    multi: false,
    items: [],
  },
  additional_rooms: {
    id: "additional_rooms",
    title: "Additional Rooms",
    headline: "Which additional rooms does your place have?",
    icon: "floor-plan",
    multi: true,
    items: [
      {
        id: "breakfast_nook",
        label: "Breakfast Nook",
        icon: "table-chair",
      },
      {
        id: "dining_room",
        label: "Dining Room",
        icon: "silverware-fork-knife",
      },
      {
        id: "family_room",
        label: "Family Room",
        icon: "sofa",
      },
      {
        id: "laundry_room",
        label: "Laundry Room",
        icon: "washing-machine",
      },
      {
        id: "library",
        label: "Library",
        icon: "bookshelf",
      },
      {
        id: "master_bath",
        label: "Master Bath",
        icon: "bathtub",
      },
      {
        id: "mud_room",
        label: "Mud Room",
        icon: "shoe-print",
      },
      {
        id: "office",
        label: "Office",
        icon: "desk-lamp",
      },
      {
        id: "pantry",
        label: "Pantry",
        icon: "cupboard",
      },
      {
        id: "recreation_room",
        label: "Recreation Room",
        icon: "billiards",
      },
      {
        id: "workshop",
        label: "Workshop",
        icon: "tools",
      },
      {
        id: "solarium_atrium",
        label: "Solarium / Atrium",
        icon: "sun-compass",
      },
      {
        id: "sun_room",
        label: "Sun Room",
        icon: "weather-sunny",
      },
      {
        id: "walk_in_closet",
        label: "Walk-In Closet",
        icon: "wardrobe",
      },
    ],
  },
  parking: {
    id: "parking",
    title: "Parking",
    headline: "What type of parking does your place have?",
    icon: "car-brake-parking",
    multi: true,
    items: [
      {
        id: "carport",
        label: "Carport",
        icon: "garage-open",
      },
      {
        id: "garage_attached",
        label: "Garage - Attached",
        icon: "garage",
      },
      {
        id: "garage_detached",
        label: "Garage - Detached",
        icon: "garage-variant",
      },
      {
        id: "off_street",
        label: "Off-Street",
        icon: "parking",
      },
      {
        id: "on_street",
        label: "On-Street",
        icon: "road-variant",
      },
      {
        id: "none",
        label: "None",
        icon: "close-circle-outline",
      },
    ],
  },
  foundation: {
    id: "foundation",
    title: "Foundation",
    headline: "What type of foundation does your place have?",
    icon: "square",
    multi: false,
    items: [
      {
        id: "slab",
        label: "Slab",
        icon: "floor-plan",
      },
      {
        id: "basement",
        label: "Basement",
        icon: "stairs-down",
      },
      {
        id: "crawlspace",
        label: "Crawlspace",
        icon: "floor-plan",
      },
      {
        id: "raised",
        label: "Raised",
        icon: "home-floor-2",
      },
      {
        id: "wood",
        label: "Wood",
        icon: "pine-tree",
      },
      {
        id: "pier",
        label: "Pier",
        icon: "pier",
      },
    ],
  },
  size: {
    id: "size",
    title: "Sizes",
    headline: "Add some basic home and property size info",
    icon: "move-resize",
    multi: false,
    items: [],
  },
  architecture: {
    id: "architecture",
    title: "Architecture",
    headline: "Which of these best describes your home's style?",
    icon: "home-outline",
    multi: false,
    items: [
      {
        id: "bungalow",
        label: "Bungalow",
        icon: "home-variant",
      },
      {
        id: "cape_cod",
        label: "Cape Cod",
        icon: "home-roof",
      },
      {
        id: "colonial",
        label: "Colonial",
        icon: "home-city-outline",
      },
      {
        id: "contemporary",
        label: "Contemporary",
        icon: "home-modern",
      },
      {
        id: "craftsman",
        label: "Craftsman",
        icon: "hammer-wrench",
      },
      {
        id: "french",
        label: "French",
        icon: "fleur-de-lis",
      },
      {
        id: "georgian",
        label: "Georgian",
        icon: "pillar",
      },
      {
        id: "loft",
        label: "Loft",
        icon: "warehouse",
      },
      {
        id: "modern",
        label: "Modern",
        icon: "city-variant-outline",
      },
      {
        id: "queen_anne_victorian",
        label: "Queen Anne / Victorian",
        icon: "castle",
      },
      {
        id: "ranch_rambler",
        label: "Ranch / Rambler",
        icon: "home",
      },
      {
        id: "santa_fe_pueblo_style",
        label: "Santa Fe / Pueblo Style",
        icon: "pyramid",
      },
      {
        id: "spanish",
        label: "Spanish",
        icon: "arch",
      },
      {
        id: "tudor",
        label: "Tudor",
        icon: "castle",
      },
      {
        id: "other",
        label: "Other",
        icon: "dots-horizontal-circle",
      },
    ],
  },
  exterior: {
    id: "exterior",
    title: "Exterior",
    headline: "Which types of exterior does your place have?",
    icon: "toy-brick",
    multi: true,
    items: [
      {
        id: "brick",
        label: "Brick",
        icon: "toy-brick",
      },
      {
        id: "cement_concrete",
        label: "Cement / Concrete",
        icon: "wall",
      },
      {
        id: "composition",
        label: "Composition",
        icon: "layers",
      },
      {
        id: "metal",
        label: "Metal",
        icon: "anvil",
      },
      {
        id: "shingle",
        label: "Shingle",
        icon: "home-roof",
      },
      {
        id: "stone",
        label: "Stone",
        icon: "square-rounded",
      },
      {
        id: "stucco",
        label: "Stucco",
        icon: "texture-box",
      },
      {
        id: "vinyl",
        label: "Vinyl",
        icon: "record-player",
      },
      {
        id: "wood",
        label: "Wood",
        icon: "pine-tree",
      },
      {
        id: "wood_products",
        label: "Wood Products",
        icon: "pine-tree-box",
      },
      {
        id: "other",
        label: "Other",
        icon: "dots-horizontal-circle",
      },
    ],
  },
  roof: {
    id: "roof",
    title: "Roof",
    headline: "Which roof types does your place have?",
    icon: "home-roof",
    multi: true,
    items: [
      {
        id: "asphalt",
        label: "Asphalt",
        icon: "road-variant",
      },
      {
        id: "built_up",
        label: "Built-Up",
        icon: "layers",
      },
      {
        id: "composition",
        label: "Composition",
        icon: "texture-box",
      },
      {
        id: "metal",
        label: "Metal",
        icon: "rectangle",
      },
      {
        id: "shake_shingle",
        label: "Shake / Shingle",
        icon: "triangle",
      },
      {
        id: "slate",
        label: "Slate",
        icon: "table-large",
      },
      {
        id: "tile",
        label: "Tile",
        icon: "square-outline",
      },
      {
        id: "other",
        label: "Other",
        icon: "dots-horizontal-circle",
      },
    ],
  },
  appliances: {
    id: "appliances",
    title: "Appliances",
    headline: "Which appliances are included?",
    icon: "dishwasher",
    multi: true,
    items: [
      {
        id: "dishwasher",
        label: "Dishwasher",
        icon: "dishwasher",
      },
      {
        id: "dryer",
        label: "Dryer",
        icon: "tumble-dryer",
      },
      {
        id: "freezer",
        label: "Freezer",
        icon: "fridge-industrial",
      },
      {
        id: "garbage_disposal",
        label: "Garbage Disposal",
        icon: "trash-can-outline",
      },
      {
        id: "microwave",
        label: "Microwave",
        icon: "microwave",
      },
      {
        id: "oven",
        label: "Oven",
        icon: "toaster-oven",
      },
      {
        id: "refrigerator",
        label: "Refrigerator",
        icon: "fridge",
      },
      {
        id: "washer",
        label: "Washer",
        icon: "washing-machine",
      },
    ],
  },
  flooring: {
    id: "flooring",
    title: "Flooring",
    headline: "Which types of flooring does your place have?",
    icon: "view-grid",
    multi: true,
    items: [
      {
        id: "carpet",
        label: "Carpet",
        icon: "floor-plan",
      },
      {
        id: "concrete",
        label: "Concrete",
        icon: "square-rounded",
      },
      {
        id: "hardwood",
        label: "Hardwood",
        icon: "tree",
      },
      {
        id: "laminate",
        label: "Laminate",
        icon: "square",
      },
      {
        id: "linoleum_vinyl",
        label: "Linoleum / Vinyl",
        icon: "card",
      },
      {
        id: "slate",
        label: "Slate",
        icon: "chart-tree",
      },
      {
        id: "softwood",
        label: "Softwood",
        icon: "pine-tree",
      },
      {
        id: "tile",
        label: "Tile",
        icon: "square-outline",
      },
    ],
  },
  cooling: {
    id: "cooling",
    title: "Cooling",
    headline: "What type of cooling does your place have?",
    icon: "air-conditioner",
    multi: true,
    items: [
      {
        id: "central",
        label: "Central",
        icon: "air-conditioner",
      },
      {
        id: "evaporative",
        label: "Evaporative",
        icon: "air-humidifier",
      },
      {
        id: "geothermal",
        label: "Geothermal",
        icon: "earth",
      },
      {
        id: "refrigeration",
        label: "Refrigeration",
        icon: "snowflake",
      },
      {
        id: "solar",
        label: "Solar",
        icon: "solar-panel",
      },
      {
        id: "wall",
        label: "Wall",
        icon: "hvac",
      },
      {
        id: "none",
        label: "None",
        icon: "close-circle-outline",
      },
    ],
  },
  heating: {
    id: "heating",
    title: "Heating",
    headline: "What type of heating does your place have?",
    icon: "radiator",
    multi: true,
    items: [
      {
        id: "baseboard",
        label: "Baseboard",
        icon: "radiator",
      },
      {
        id: "forced_air",
        label: "Forced Air",
        icon: "fan",
      },
      {
        id: "geothermal",
        label: "Geothermal",
        icon: "earth",
      },
      {
        id: "heat_pump",
        label: "Heat Pump",
        icon: "hvac",
      },
      {
        id: "radiant",
        label: "Radiant",
        icon: "sun-thermometer-outline",
      },
      {
        id: "stove",
        label: "Stove",
        icon: "fireplace",
      },
      {
        id: "wall",
        label: "Wall",
        icon: "radiator",
      },
      {
        id: "none",
        label: "None",
        icon: "close-circle-outline",
      },
    ],
  },
  heating_fuel: {
    id: "heating_fuel",
    title: "Heating Fuel",
    headline: "What type of heating fuel does your place use?",
    icon: "lightning-bolt",
    multi: true,
    items: [
      {
        id: "coal",
        label: "Coal",
        icon: "hexagon",
      },
      {
        id: "electric",
        label: "Electric",
        icon: "lightning-bolt",
      },
      {
        id: "gas",
        label: "Gas",
        icon: "gas-cylinder",
      },
      {
        id: "oil",
        label: "Oil",
        icon: "oil",
      },
      {
        id: "propane_butane",
        label: "Propane / Butane",
        icon: "propane-tank",
      },
      {
        id: "solar",
        label: "Solar",
        icon: "solar-panel",
      },
      {
        id: "wood_pellet",
        label: "Wood / Pellet",
        icon: "pine-tree",
      },
      {
        id: "none",
        label: "None",
        icon: "close-circle-outline",
      },
    ],
  },
  view: {
    id: "view",
    title: "View",
    headline: "What type of view does your place have?",
    icon: "binoculars",
    multi: true,
    items: [
      {
        id: "none",
        label: "None",
        icon: "close-circle-outline",
      },
      {
        id: "city",
        label: "City",
        icon: "city",
      },
      {
        id: "mountain",
        label: "Mountain",
        icon: "image-filter-hdr",
      },
      {
        id: "park",
        label: "Park",
        icon: "pine-tree",
      },
      {
        id: "territorial",
        label: "Territorial",
        icon: "map-marker-radius",
      },
      {
        id: "water",
        label: "Water",
        icon: "water",
      },
    ],
  },
  indoor_features: {
    id: "indoor_features",
    title: "Indoor Features",
    headline: "What indoor features does your place have?",
    icon: "fireplace",
    multi: true,
    items: [
      {
        id: "attic",
        label: "Attic",
        icon: "home-roof",
      },
      {
        id: "cable_ready",
        label: "Cable Ready",
        icon: "television-guide",
      },
      {
        id: "ceiling_fans",
        label: "Ceiling Fans",
        icon: "ceiling-fan",
      },
      {
        id: "double_pane_storm_windows",
        label: "Double Pane / Storm Windows",
        icon: "window-closed",
      },
      {
        id: "fireplace",
        label: "Fireplace",
        icon: "fireplace",
      },
      {
        id: "intercom_system",
        label: "Intercom System",
        icon: "deskphone",
      },
      {
        id: "jetted_tub",
        label: "Jetted Tub",
        icon: "hot-tub",
      },
      {
        id: "mother_in_law_apartment",
        label: "Mother-In-Law Apartment",
        icon: "home-floor-1",
      },
      {
        id: "security_system",
        label: "Security System",
        icon: "security",
      },
      {
        id: "skylights",
        label: "Skylights",
        icon: "weather-night",
      },
      {
        id: "vaulted_ceiling",
        label: "Vaulted Ceiling",
        icon: "ceiling-light",
      },
      {
        id: "wet_bar",
        label: "Wet Bar",
        icon: "cup-water",
      },
      {
        id: "wired",
        label: "Wired",
        icon: "lan-connect",
      },
      {
        id: "none",
        label: "None",
        icon: "close-circle-outline",
      },
    ],
  },
  building_amenities: {
    id: "building_amenities",
    title: "Building Amenities",
    headline: "What building amenities does your place have?",
    icon: "basketball-hoop",
    multi: true,
    items: [
      {
        id: "none",
        label: "None",
        icon: "close-circle-outline",
      },
      {
        id: "assisted_living_community",
        label: "Assisted Living Community",
        icon: "human-wheelchair",
      },
      {
        id: "basketball_court",
        label: "Basketball Court",
        icon: "basketball-hoop",
      },
      {
        id: "controlled_access",
        label: "Controlled Access",
        icon: "lock",
      },
      {
        id: "disabled_access",
        label: "Disabled Access",
        icon: "wheelchair-accessibility",
      },
      {
        id: "doorman",
        label: "Doorman",
        icon: "human-greeting",
      },
      {
        id: "elevator",
        label: "Elevator",
        icon: "elevator",
      },
      {
        id: "fitness_center",
        label: "Fitness Center",
        icon: "dumbbell",
      },
      {
        id: "gated_entry",
        label: "Gated Entry",
        icon: "gate",
      },
      {
        id: "near_transportation",
        label: "Near Transportation",
        icon: "bus",
      },
      {
        id: "over_55_active_community",
        label: "Over 55 Active Community",
        icon: "walk",
      },
      {
        id: "sports_court",
        label: "Sports Court",
        icon: "rectangle-outline",
      },
      {
        id: "storage",
        label: "Storage",
        icon: "archive",
      },
      {
        id: "tennis_court",
        label: "Tennis Court",
        icon: "tennis",
      },
    ],
  },
  outdoor_amenities: {
    id: "outdoor_amenities",
    title: "Outdoor Amenities",
    headline: "What outdoor amenities does your place have?",
    icon: "fence",
    multi: true,
    items: [
      {
        id: "none",
        label: "None",
        icon: "close-circle-outline",
      },
      {
        id: "balcony_patio",
        label: "Balcony / Patio",
        icon: "balcony",
      },
      {
        id: "barbecue_area",
        label: "Barbecue Area",
        icon: "grill",
      },
      {
        id: "deck",
        label: "Deck",
        icon: "view-grid-plus",
      },
      {
        id: "dock",
        label: "Dock",
        icon: "dock-bottom",
      },
      {
        id: "fenced_yard",
        label: "Fenced Yard",
        icon: "fence",
      },
      {
        id: "garden",
        label: "Garden",
        icon: "flower",
      },
      {
        id: "greenhouse",
        label: "Greenhouse",
        icon: "greenhouse",
      },
      {
        id: "hot_tub_spa",
        label: "Hot Tub / Spa",
        icon: "hot-tub",
      },
      {
        id: "lawn",
        label: "Lawn",
        icon: "grass",
      },
      {
        id: "pond",
        label: "Pond",
        icon: "water",
      },
      {
        id: "pool",
        label: "Pool",
        icon: "pool",
      },
      {
        id: "porch",
        label: "Porch",
        icon: "home-export-outline",
      },
      {
        id: "rv_parking",
        label: "RV Parking",
        icon: "rv-truck",
      },
      {
        id: "sauna",
        label: "Sauna",
        icon: "spa",
      },
      {
        id: "sprinkler_system",
        label: "Sprinkler System",
        icon: "sprinkler-variant",
      },
      {
        id: "waterfront",
        label: "Waterfront",
        icon: "ferry",
      },
    ],
  },
  warranty: {
    id: "warranty",
    title: "Warranty",
    headline: "Are you providing a warranty?",
    icon: "certificate",
    multi: false,
    items: [],
  },
  tours: {
    id: "tours",
    title: "Tours",
    headline: "Setup tour slots",
    icon: "calendar-multiselect",
    multi: false,
    items: [],
  },
  photos: {
    id: "photos",
    title: "Photos",
    headline: "Add some photos of your place",
    icon: "camera-burst",
    multi: false,
    items: [],
  },
  docs: {
    id: "docs",
    title: "Documents",
    headline: "Add public documents such as a floor plan",
    icon: "file-document-multiple",
    multi: false,
    items: [],
  },
  description: {
    id: "description",
    title: "Description",
    headline: "Share what makes your place special",
    icon: "file-document-edit",
    multi: false,
    items: [],
  },
  price: {
    id: "price",
    title: "Pricing",
    headline: "Set your price",
    icon: "currency-usd",
    multi: false,
    items: [],
  },
}

export type listingPhaseIds = keyof typeof listingPhases
