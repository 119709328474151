import { DateTime } from "luxon"
import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { toursUpdateTour } from "../../services/client"
import { withSetPropAction } from "../withSetPropAction"

export enum TourStatus {
  REQUESTED = "requested",
  CANCELLED = "cancelled",
  DECLINED = "declined",
  APPROVED = "approved",
}

export enum TourStatusUpdate {
  CANCELLED = "cancelled",
  DECLINED = "declined",
  APPROVED = "approved",
}

export const TourModel = types
  .model("Tour")
  .props({
    id: types.identifier,
    created_at: types.Date,
    updated_at: types.maybeNull(types.Date),
    userId: types.string,
    listing_id: types.string,
    start: types.Date,
    end: types.Date,
    timezone: types.string,
    status: types.enumeration("TourStatus", ["requested", "cancelled", "declined", "approved"]),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    async updateTour({ status }: { status: TourStatusUpdate }) {
      const result: { error: string | null; errorDetails?: string | null } = {
        error: "Failed to update tour",
      }

      const response = await toursUpdateTour({
        path: { id: `${store.id}` },
        body: { status },
      })

      if (response.error) {
        if (Array.isArray(response.error.detail)) {
          result.errorDetails = response.error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = response.error.detail
        }
      } else {
        return { error: null }
      }

      return result
    },
  }))
  .views((store) => ({
    get isPast() {
      const now = DateTime.now()
      return DateTime.fromJSDate(store.end) < now
    },
  }))

export interface Tour extends Instance<typeof TourModel> {}
export interface TourSnapshotOut extends SnapshotOut<typeof TourModel> {}
export interface TourSnapshotIn extends SnapshotIn<typeof TourModel> {}
