import { CircularProgress, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useApiCallState } from '../../hooks'
import { useStores } from '../../models'

interface FeedbackProps {
  handleClose: () => void
}

export const Feedback: React.FC<FeedbackProps> = observer(({ handleClose }) => {
  const { accountStore } = useStores()
  const { loading, setLoading, setMsg } = useApiCallState()
  const [text, setText] = useState('')
  const maxlength = 500
  const user = accountStore.user
  if (!user) return null

  async function handleSubmit() {
    setLoading(true)

    const { error } = await user.submitFeedback({ feedback: text })
    if (error) {
      setMsg(error, 'error')
    } else {
      setMsg('Feedback submitted', 'success')
      handleClose()
    }

    setLoading(false)
  }

  return (
    <Dialog open={true} fullWidth maxWidth='sm' onClose={handleClose}>
      <DialogTitle>Issue / Feature Request</DialogTitle>
      <DialogContent>
        <TextField
          disabled={loading}
          autoFocus
          required
          id='feedback'
          multiline
          rows={8}
          fullWidth
          placeholder='How can we help you better?'
          value={text}
          onChange={e => {
            if (e.target.value.length <= maxlength) {
              setText(e.target.value)
            }
          }}
        />

        <Typography variant='body2'>
          {text.length} / {maxlength}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='error' disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={loading}>
          Submit{' '}
          {loading && (
            <CircularProgress
              size={24}
              sx={theme => ({ ml: theme.spacing(1) })}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
})
