const BaseConfig = {
  googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY || '',
  anonKey: 'iZGJhIiwicm9sZSI6Im',
  firebase: {
    apiKey: 'AIzaSyB0y8VwsODcUdp3VK9Hloafi3-5h8eoWk0',
    authDomain: 'homy.homes',
    projectId: 'homy-408915',
    storageBucket: 'homy-408915.appspot.com',
    messagingSenderId: '564354690082',
    appId: '1:564354690082:web:e546e4a1e4330b7dfd2e7b',
    measurementId: 'G-000MXQ5QZ7',
  },
}

export default BaseConfig
