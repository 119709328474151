import { useMediaQuery } from '@mui/material'
import {
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  signInWithEmailLink,
} from 'firebase/auth'
import { useCallback, useEffect } from 'react'
import { useRealtime } from '../../../shared/hooks/useRealtime'
import Config from '../config'
import { useStores } from '../models'
import { localStore, LocalStoreKey, LocalStoreOp } from '../utils/storage'

export function useStartupActions() {
  const rootStore = useStores()
  const { accountStore, notificationStore, chatStore } = rootStore
  const loggedIn = accountStore.user.loggedIn
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const isDarkMode = accountStore.darkMode
  const auth = getAuth()

  const getIdToken = useCallback(async (refresh?: boolean) => {
    const auth = getAuth()
    const user = auth.currentUser

    if (user) {
      const accessToken = await user.getIdToken(refresh)
      if (accessToken) {
        return accessToken
      }
    }

    return null
  }, [])

  useRealtime({
    host: Config.rtHost,
    chatStore,
    notificationStore,
    getIdToken,
    loggedIn: !!accountStore.user.accessToken,
  })

  useEffect(() => {
    const savedTheme = localStore(LocalStoreOp.GET, LocalStoreKey.THEME)

    let darkMode = isDarkMode
    if (savedTheme) {
      darkMode = savedTheme === 'dark'
    } else {
      darkMode = prefersDarkMode
      localStore(LocalStoreOp.SET, LocalStoreKey.THEME, 'dark')
    }

    accountStore.setProp('darkMode', darkMode)
  }, [accountStore, prefersDarkMode, isDarkMode])

  useEffect(() => {
    // Handle email link sign in

    async function linkSignIn() {
      const email = localStore(LocalStoreOp.GET, LocalStoreKey.LINK_EMAIL)
      if (email) {
        try {
          await signInWithEmailLink(auth, email, window.location.href)
        } catch (error) {
          console.error(error)
        }
      }
    }

    // Confirm the link is a sign-in with email link.
    if (isSignInWithEmailLink(auth, window.location.href)) {
      linkSignIn()
    }
  }, [auth])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        const accessToken = await user.getIdToken()

        accountStore.setProp('user', {
          ...accountStore.user,
          email: user.email,
          name: user.displayName,
          picture: user.photoURL,
          userId: user.uid,
          loggedIn: true,
          accessToken,
        })

        accountStore.setProp('loaded', true)
      } else {
        auth.signOut()
        accountStore.reset()
      }
    })

    return () => unsubscribe()
  }, [auth, rootStore, accountStore])

  useEffect(() => {
    if (loggedIn && accountStore.user && accountStore.user.userId) {
      accountStore.user.fetchProfile()
      notificationStore.fetchNotifications({})
    }
  }, [notificationStore, accountStore.user, loggedIn])

  return null
}
