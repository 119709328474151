import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { offersUpdateOffer } from "../../../shared/services/client"
import { withSetPropAction } from "../withSetPropAction"
import { CounterModel } from "./Counter"

enum OfferStatusUpdate {
  CANCELLED = "cancelled",
  DECLINED = "declined",
  COUNTERED = "countered",
  ACCEPTED = "accepted",
}

export enum OfferStatus {
  ACTIVE = "active",
  CANCELLED = "cancelled",
  DECLINED = "declined",
  COUNTERED = "countered",
  ACCEPTED = "accepted",
}

export const AgreementDocModel = types.model("AgreementDoc").props({
  name: types.string,
  createdAt: types.string,
})

export const OfferModel = types
  .model("Offer")
  .props({
    id: types.identifier,
    created_at: types.Date,
    updated_at: types.maybeNull(types.Date),
    user_id: types.string,
    listing_id: types.string,
    price: types.number,
    earnest: types.number,
    due_dilligence: types.number,
    file_id: types.string,
    funds_file_id: types.string,
    agent_commission: types.maybeNull(types.number),
    status: types.enumeration("OfferStatus", [
      "active",
      "cancelled",
      "declined",
      "countered",
      "accepted",
    ]),
    counter: types.maybeNull(CounterModel),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    async updateOffer() {
      const result: { error: string | null; errorDetails?: string | null } = {
        error: null,
      }

      const response = await offersUpdateOffer({
        path: { id: store.id },
        body: {
          status: store.status as OfferStatusUpdate,
          counter: store.counter,
        },
      })

      if (response.error) {
        result.error = "Failed to update offer"
        if (Array.isArray(response.error.detail)) {
          result.errorDetails = response.error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = response.error.detail
        }
        return result
      }

      return result
    },
  }))

export interface Offer extends Instance<typeof OfferModel> {}
export interface OfferSnapshotOut extends SnapshotOut<typeof OfferModel> {}
export interface OfferSnapshotIn extends SnapshotIn<typeof OfferModel> {}
