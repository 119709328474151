/**
 * Get map region for multiple coordinates so they are all shown
 * Source: https://github.com/react-native-maps/react-native-maps/issues/505#issuecomment-243423775
 */
export function getRegionForCoordinates(points: { latitude: number; longitude: number }[]) {
  // points should be an array of { latitude: X, longitude: Y }
  let minX: number
  let maxX: number
  let minY: number
  let maxY: number
  const padding = 0.2

  // init first point
  ;((point) => {
    minX = point.latitude
    maxX = point.latitude
    minY = point.longitude
    maxY = point.longitude
  })(points[0])

  // calculate rect
  points.forEach((point) => {
    minX = Math.min(minX, point.latitude)
    maxX = Math.max(maxX, point.latitude)
    minY = Math.min(minY, point.longitude)
    maxY = Math.max(maxY, point.longitude)
  })

  const midX = (minX + maxX) / 2
  const midY = (minY + maxY) / 2
  const deltaX = maxX - minX + padding
  const deltaY = maxY - minY + padding

  return {
    lat: midX,
    lng: midY,
    latitudeDelta: deltaX,
    longitudeDelta: deltaY,
  }
}
