import { DateTime } from "luxon"
import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import {
  Appliance,
  Architecture,
  BuildingAmenities,
  Cooling,
  Exterior,
  Floor,
  Foundation,
  Heating,
  HeatingFuel,
  HoaCadence,
  IndoorFeatures,
  listingsGenerateListingDescription,
  listingsGetPasscode,
  listingsUpdateListing,
  listingsUpdatePasscode,
  ListingType,
  LotSizeUnit,
  OutdoorAmenities,
  Parking,
  Roof,
  Room,
  View,
} from "../../../shared/services"
import { listingPhases } from "../../data"
import { customColors } from "../../theme/customColors"
import { getVanityAddress } from "../../utils/getAddressId"
import { withSetPropAction } from "../withSetPropAction"
import { TourScheduleModel } from "./TourSchedule"

const currentYear = new Date().getFullYear()

export enum ListingStatus {
  DRAFT = "draft",
  LIVE = "live",
  CONTRACT = "contract",
  SOLD = "sold",
}

/**
 * Model for Listing data
 */
export const ListingModel = types
  .model("Listing")
  .props({
    created_at: types.Date,
    updated_at: types.maybeNull(types.Date),
    status: types.maybeNull(types.enumeration("Status", Object.values(ListingStatus))),
    id: types.identifier,
    place_id: types.string,
    owner_id: types.string,
    team_id: types.maybeNull(types.string),
    show_team: types.maybeNull(types.boolean),
    team_name: types.maybeNull(types.string),
    street: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    zip_code: types.maybeNull(types.string),
    address: types.string,
    county: types.maybeNull(types.string),
    latitude: types.maybeNull(types.number),
    longitude: types.maybeNull(types.number),
    images: types.array(types.string),
    image_path: types.string,
    beds: types.optional(types.number, 0),
    full_baths: types.optional(types.number, 0),
    half_baths: types.optional(types.number, 0),
    total_rooms: types.optional(types.number, 0),
    square_feet: types.maybeNull(types.number),
    garage_spaces: types.maybeNull(types.number),
    lot_size: types.maybeNull(types.string),
    lot_size_unit: types.optional(types.enumeration("Unit", ["acres", "sqft"]), "acres"),
    number_of_stories: types.maybeNull(types.number),
    year_built: types.maybeNull(types.number),
    roof_year: types.maybeNull(types.number),
    hoa_fee: types.maybeNull(types.number),
    accepted_offer: types.maybeNull(types.string),
    warranty_provider: types.maybeNull(types.string),
    warranty_type: types.maybeNull(types.string),
    tour_schedule: types.maybeNull(types.array(TourScheduleModel)),
    has_passcode: types.maybeNull(types.boolean),
    passcode: types.maybeNull(types.string),
    passcodeInstructions: types.maybeNull(types.string),
    commission: types.maybeNull(types.number),
    commission_type: types.optional(
      types.enumeration("CommissionType", ["percent", "dollar"]),
      "percent",
    ),
    hoa_cadence: types.optional(
      types.enumeration("Cadence", ["monthly", "yearly", "quarterly"]),
      "monthly",
    ),
    price: types.maybeNull(types.number),
    description: types.maybeNull(types.string),
    type: types.maybeNull(
      types.enumeration(
        "Type",
        listingPhases.type.items.map((t) => t.id),
      ),
    ),
    appliances: types.maybeNull(
      types.array(
        types.enumeration(
          "Appliance",
          listingPhases.appliances.items.map((t) => t.id),
        ),
      ),
    ),
    flooring: types.maybeNull(
      types.array(
        types.enumeration(
          "Flooring",
          listingPhases.flooring.items.map((t) => t.id),
        ),
      ),
    ),
    architecture: types.maybeNull(
      types.enumeration(
        "Architecture",
        listingPhases.architecture.items.map((t) => t.id),
      ),
    ),
    exterior: types.maybeNull(
      types.array(
        types.enumeration(
          "Exterior",
          listingPhases.exterior.items.map((t) => t.id),
        ),
      ),
    ),
    foundation: types.maybeNull(
      types.enumeration(
        "Foundation",
        listingPhases.foundation.items.map((t) => t.id),
      ),
    ),
    cooling: types.maybeNull(
      types.array(
        types.enumeration(
          "Cooling",
          listingPhases.cooling.items.map((t) => t.id),
        ),
      ),
    ),
    heating: types.maybeNull(
      types.array(
        types.enumeration(
          "Heating",
          listingPhases.heating.items.map((t) => t.id),
        ),
      ),
    ),
    heating_fuel: types.maybeNull(
      types.array(
        types.enumeration(
          "HeatingFuel",
          listingPhases.heating_fuel.items.map((t) => t.id),
        ),
      ),
    ),
    parking: types.maybeNull(
      types.array(
        types.enumeration(
          "Parking",
          listingPhases.parking.items.map((t) => t.id),
        ),
      ),
    ),
    roof: types.maybeNull(
      types.array(
        types.enumeration(
          "Roof",
          listingPhases.roof.items.map((t) => t.id),
        ),
      ),
    ),
    view: types.maybeNull(
      types.array(
        types.enumeration(
          "View",
          listingPhases.view.items.map((t) => t.id),
        ),
      ),
    ),
    building_amenities: types.maybeNull(
      types.array(
        types.enumeration(
          "BuildingAmenities",
          listingPhases.building_amenities.items.map((t) => t.id),
        ),
      ),
    ),
    outdoor_amenities: types.maybeNull(
      types.array(
        types.enumeration(
          "OutdoorAmenities",
          listingPhases.outdoor_amenities.items.map((t) => t.id),
        ),
      ),
    ),
    indoor_features: types.maybeNull(
      types.array(
        types.enumeration(
          "IndoorFeatures",
          listingPhases.indoor_features.items.map((t) => t.id),
        ),
      ),
    ),
    additional_rooms: types.maybeNull(
      types.array(
        types.enumeration(
          "AdditionalRooms",
          listingPhases.additional_rooms.items.map((t) => t.id),
        ),
      ),
    ),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    updateImages(file_names: string[]) {
      store.images.push(...file_names)
    },
  }))
  .actions((store) => ({
    async updateListing() {
      const result: { error: string | null; errorDetails?: string | null } = { error: null }

      const response = await listingsUpdateListing({
        path: { id: store.id },
        body: {
          team_id: store.team_id,
          show_team: store.show_team,
          status: store.status as ListingStatus,
          price: store.price,
          type: store.type as ListingType,
          description: store.description,
          square_feet: store.square_feet,
          garage_spaces: store.garage_spaces,
          beds: store.beds,
          full_baths: store.full_baths,
          half_baths: store.half_baths,
          lot_size: store.lot_size ? parseFloat(store.lot_size) : 0,
          lot_size_unit: store.lot_size_unit as LotSizeUnit,
          year_built: store.year_built,
          hoa_fee: store.hoa_fee,
          hoa_cadence: store.hoa_cadence as HoaCadence,
          appliances: store.appliances as Appliance[],
          flooring: store.flooring as Floor[],
          additional_rooms: store.additional_rooms as Room[],
          total_rooms: store.total_rooms,
          cooling: store.cooling as Cooling[],
          heating: store.heating as Heating[],
          heating_fuel: store.heating_fuel as HeatingFuel[],
          indoor_features: store.indoor_features as IndoorFeatures[],
          building_amenities: store.building_amenities as BuildingAmenities[],
          architecture: store.architecture as Architecture,
          exterior: store.exterior as Exterior[],
          outdoor_amenities: store.outdoor_amenities as OutdoorAmenities[],
          number_of_stories: store.number_of_stories,
          parking: store.parking as Parking[],
          roof: store.roof as Roof[],
          roof_year: store.roof_year,
          foundation: store.foundation as Foundation,
          view: store.view as View[],
          place_id: store.place_id,
          warranty_provider: store.warranty_provider,
          warranty_type: store.warranty_type,
          tour_schedule: store.tour_schedule,
          commission: store.commission ?? null,
          commission_type: store.commission_type as CommissionType,
          images: store.images,
        },
      })

      if (response.error || !response.data) {
        result.error = "Failed to update listing"

        if (Array.isArray(response.error.detail)) {
          result.errorDetails = response.error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = response.error.detail
        }

        return result
      } else {
        store.setProp("updated_at", DateTime.utc().toJSDate())
      }

      return result
    },
    async generateDescription({ improve }: { improve?: boolean }) {
      const result: { error: string | null; errorDetails?: string | null } = { error: null }

      const response = await listingsGenerateListingDescription({
        path: { id: store.id },
        query: { improve },
      })

      if (response.error) {
        result.error = "Failed to generate description"

        if (Array.isArray(response.error.detail)) {
          result.errorDetails = response.error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = response.error.detail
        }
        return result
      }

      store.setProp("description", response.data.description)

      return result
    },
  }))
  .actions((store) => ({
    handleTimeSelect({
      start,
      hours,
      minutes,
    }: {
      start: boolean
      hours: number
      minutes: number
    }) {
      const now = DateTime.now()
      const time = DateTime.fromFormat(`${hours}:${minutes}`, "H:m")
      const isoTime = time.toISOTime()

      // Filter out past dates
      const validSchedule =
        store.tour_schedule &&
        store.tour_schedule.filter((s) => {
          const date = DateTime.fromISO(s.date)
          return date && date.startOf("day") >= now.startOf("day")
        })

      if (validSchedule && isoTime) {
        store.setProp(
          "tour_schedule",
          validSchedule
            ? validSchedule.map((s) => ({ ...s, [start ? "start" : "end"]: isoTime }))
            : [],
        )
      }

      return null
    },
    handleDayPress(day: DateData, startTime: DateTime, endTime: DateTime) {
      const now = DateTime.now()
      const dayStartTime = startTime.toISOTime()
      const dayEndTime = endTime.toISOTime()

      if (dayStartTime && dayEndTime) {
        // Filter out past dates
        const validSchedule =
          store.tour_schedule &&
          store.tour_schedule.filter((s) => {
            const date = DateTime.fromISO(s.date)
            return date && date.startOf("day") >= now.startOf("day")
          })

        const existingDate = validSchedule && validSchedule.find((s) => s.date === day.dateString)

        if (existingDate) {
          store.setProp(
            "tour_schedule",
            validSchedule ? validSchedule.filter((s) => s.date !== day.dateString) : [],
          )
        } else {
          const newRecord = {
            date: day.dateString,
            start: dayStartTime,
            end: dayEndTime,
          }

          store.setProp(
            "tour_schedule",
            validSchedule
              ? [...validSchedule.filter((s) => s.date !== day.dateString), newRecord]
              : [newRecord],
          )
        }
      }

      return null
    },
    async getDecryptedPasscode() {
      const result: {
        error: string | null
        errorDetails?: string | null
        decryptedPasscode: string | null
        decryptedInstructions: string | null
      } = {
        error: "Failed to fetch decrypted passcode",
        decryptedPasscode: null,
        decryptedInstructions: null,
      }

      const response = await listingsGetPasscode({
        path: { id: store.id },
      })

      if (response.error) {
        if (Array.isArray(response.error.detail)) {
          result.errorDetails = response.error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = response.error.detail
        }
      } else if (response.data) {
        store.setProp("passcode", response.data.passcode)
        if (response.data.instructions) {
          store.setProp("passcodeInstructions", response.data.instructions)
        }
        return {
          error: null,
          errorDetails: null,
          decryptedPasscode: response.data.passcode,
          decryptedInstructions: response.data.instructions,
        }
      }

      return result
    },
    async updatePasscode({ passcode, instructions }: { passcode: string; instructions?: string }) {
      const result: {
        error: string | null
        errorDetails?: string | null
      } = {
        error: "Failed to update passcode",
      }

      const response = await listingsUpdatePasscode({
        path: { id: `${store.id}` },
        body: {
          passcode,
          instructions,
        },
      })

      if (response.error) {
        if (Array.isArray(response.error.detail)) {
          result.errorDetails = response.error.detail.map((e) => e.msg).join(", ")
        } else {
          result.errorDetails = response.error.detail
        }
      } else if (response.data) {
        return {
          error: null,
          errorDetails: null,
        }
      }

      return result
    },
  }))
  .views((store) => ({
    get trimDescription() {
      if (!store.description) {
        return { long: false, text: "" }
      }
      const description = store.description.trim()

      const descriptionLines = description.split("\n")
      const longTextDescription = description.length > 400
      const longLineDescription = descriptionLines.length > 10
      const longDescription = longTextDescription || longLineDescription

      return {
        long: longDescription,
        text: longDescription
          ? `${descriptionLines.slice(0, 10).join("\n").slice(0, 400)}...`
          : description,
      }
    },
    get totalBaths() {
      return store.full_baths + store.half_baths
    },
    get publicUrl() {
      const urlId = getVanityAddress(store.address)
      return `https://homy.homes/listings/${urlId}/${store.id}`
    },
    get statusIconParams() {
      const params: {
        icon: "check-circle" | "cash-check" | "file-sign" | "pencil"
        color: string
        publicColor: "success" | "warning" | "error"
      } = {
        icon:
          store.status === ListingStatus.LIVE
            ? "check-circle"
            : store.status === ListingStatus.SOLD
              ? "cash-check"
              : store.status === ListingStatus.CONTRACT
                ? "file-sign"
                : "pencil",
        color: store.status === ListingStatus.DRAFT ? customColors.amber500 : customColors.green500,
        publicColor:
          store.status === ListingStatus.LIVE
            ? "success"
            : store.status === ListingStatus.CONTRACT
              ? "warning"
              : "error",
      }

      return params
    },
    get progress() {
      const year_builtInvalid =
        !!store.year_built && (store.year_built < 1900 || store.year_built > currentYear)

      const statuses = {
        team: true,
        type: !!store.type,
        details: !!store.year_built && !year_builtInvalid,
        rooms: !!(store.total_rooms && store.total_rooms > 0),
        additional_rooms: !!store.additional_rooms && store.additional_rooms.length > 0,
        parking: !!store.parking && store.parking.length > 0,
        foundation: !!store.foundation,
        size: !!store.square_feet && !!store.lot_size && !isNaN(parseFloat(store.lot_size)),
        architecture: !!store.architecture,
        exterior: !!store.exterior && store.exterior.length > 0,
        roof: !!store.roof && store.roof.length > 0,
        appliances: !!store.appliances && store.appliances.length > 0,
        flooring: !!store.flooring && store.flooring.length > 0,
        cooling: !!store.cooling && store.cooling.length > 0,
        heating: !!store.heating && store.heating.length > 0,
        heating_fuel: !!store.heating_fuel && store.heating_fuel.length > 0,
        view: !!store.view && store.view.length > 0,
        indoor_features: !!store.indoor_features && store.indoor_features.length > 0,
        building_amenities: !!store.building_amenities && store.building_amenities.length > 0,
        outdoor_amenities: !!store.outdoor_amenities && store.outdoor_amenities.length > 0,
        warranty: true,
        tours: true,
        docs: true,
        photos: true,
        description: !!store.description,
        price: !!store.price && !!store.description,
      }

      let totalPhases = 0
      let completedPhases = 0
      let nextPhase: string | null = null
      Object.entries(statuses).forEach(([phase, completed]) => {
        totalPhases += 1
        if (completed) {
          completedPhases += 1
        } else if (nextPhase === null) {
          nextPhase = phase
        }
      })

      if (nextPhase === null) {
        nextPhase = "listingDetails"
      }

      return {
        statuses,
        percentage: Math.round((completedPhases / totalPhases) * 100) / 100,
        nextPhase,
      }
    },
    commissionAmount(newPrice?: number) {
      const calcPrice = newPrice ?? store.price
      if (calcPrice && store.commission) {
        const comNumber = store.commission
        if (isNaN(comNumber)) return 0

        if (store.commission_type === CommissionType.percent) {
          return (comNumber / 100) * calcPrice
        } else if (store.commission_type === CommissionType.dollar) {
          return store.commission
        }
      }

      return 0
    },
    get imageUrls() {
      return store.images.map((i) => `${store.image_path}/${i}`)
    },
  }))

export interface Listing extends Instance<typeof ListingModel> {}
export interface ListingSnapshotOut extends SnapshotOut<typeof ListingModel> {}
export interface ListingSnapshotIn extends SnapshotIn<typeof ListingModel> {}

export enum CommissionType {
  percent = "percent",
  dollar = "dollar",
}

type DateData = {
  year: number
  month: number
  day: number
  timestamp: number
  dateString: string
}
