import { DateTime } from "luxon"

export const timeAgo = (date: Date) => {
  const now = DateTime.now()
  const timeDiff = now.diff(DateTime.fromJSDate(date), ["days", "hours", "minutes", "seconds"])

  return timeDiff.days >= 1
    ? `${Math.round(timeDiff.days)}d`
    : timeDiff.hours >= 1
      ? `${Math.round(timeDiff.hours)}h`
      : timeDiff.minutes >= 1
        ? `${Math.round(timeDiff.minutes)}m`
        : `${Math.round(timeDiff.seconds > 0 ? timeDiff.seconds : 0)}s`
}
