import {
  Button,
  CircularProgress,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  useMediaQuery,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { MsgStatus } from '../../../../shared/models'
import { useApiCallState } from '../../hooks'
import { useTheme } from '../../theme'
import { AuthProviders, signIn, signInWithEmail } from '../../utils/auth'
import { localStore, LocalStoreKey, LocalStoreOp } from '../../utils/storage'
import { McIcon } from '../McIcon'

interface IFormInput {
  email: string
}

interface LoginButtonsProps {
  onClick?: () => void
}

export const LoginButtons: React.FC<LoginButtonsProps> = observer(
  ({ onClick }) => {
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const { loading, setLoading, setMsg } = useApiCallState()
    const { register, handleSubmit, formState } = useForm<IFormInput>({
      mode: 'onChange',
    })
    const cachedEmail = localStore(LocalStoreOp.GET, LocalStoreKey.LINK_EMAIL)

    const onSubmit = async (data: IFormInput) => {
      setLoading(true)

      const { error } = await signInWithEmail(data.email)
      if (error) {
        setMsg(error, MsgStatus.ERROR)
      } else {
        setMsg(`A login link has been sent to ${data.email}`, MsgStatus.SUCCESS)
        if (onClick) {
          onClick()
        }
      }

      setLoading(false)
    }

    return (
      <>
        <ListItem>
          <ListItemText
            primary='Welcome! 👋'
            secondary='Sign in to continue'
            primaryTypographyProps={{ variant: 'h5' }}
          />
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton>
            <Button
              color='secondary'
              fullWidth
              variant='contained'
              startIcon={<McIcon path='google' />}
              onClick={async () => {
                await signIn({
                  providerType: AuthProviders.GOOGLE,
                  redirect: isSmall,
                })
                if (onClick) {
                  onClick()
                }
              }}
            >
              Sign in with Google
            </Button>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton>
            <Button
              color='secondary'
              fullWidth
              variant='contained'
              startIcon={<McIcon path='apple' />}
              onClick={async () => {
                await signIn({
                  providerType: AuthProviders.APPLE,
                  redirect: isSmall,
                })
                if (onClick) {
                  onClick()
                }
              }}
            >
              Sign in with Apple
            </Button>
          </ListItemButton>
        </ListItem>

        <Divider sx={{ my: 1 }} />

        <ListItem>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label='Email Address'
              sx={{ mb: 1 }}
              fullWidth
              variant='outlined'
              type='email'
              size='small'
              error={!!formState.errors.email?.message}
              helperText={formState.errors.email?.message}
              defaultValue={cachedEmail || ''}
              {...register('email', {
                required: true,
                disabled: loading,
                maxLength: 50,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Enter a valid email',
                },
              })}
            />

            <Button
              variant='contained'
              color='success'
              fullWidth
              startIcon={<McIcon path='login' />}
              disabled={!formState.isValid || loading}
              type='submit'
            >
              Sign In
              {loading && <CircularProgress size={24} className='ml-1' />}
            </Button>
          </form>
        </ListItem>
      </>
    )
  }
)
