import { Listing } from '../../../../shared/models'
import { formatCurrency } from '../../../../shared/utils'
import { McIcon } from '../../Components'

export const useListingAttributes = (listing: Listing) => {
  const highlight = [
    {
      icon: <McIcon path='grass' size={1} />,
      title: `${listing.lot_size} ${listing.lot_size_unit} Lot`,
    },
    {
      icon: <McIcon path='hammer-wrench' size={1} />,
      title: `Built in ${listing.year_built}`,
    },
    {
      icon: <McIcon path='cash-multiple' size={1} />,
      title:
        listing.square_feet && listing.price
          ? `${formatCurrency(Math.round(listing.price / listing.square_feet))} / Sqft`
          : null,
    },
  ]

  if (listing.warranty_type) {
    highlight.push({
      icon: <McIcon path='certificate' size={1} />,
      title: `${listing.warranty_type} Warranty `,
    })
  } else if (listing.hoa_fee) {
    highlight.push({
      icon: <McIcon path='account-group' size={1} />,
      title: `HOA ${formatCurrency(listing.hoa_fee)} ${listing.hoa_cadence}`,
    })
  } else if (listing.garage_spaces) {
    highlight.push({
      icon: <McIcon path='garage-open-variant' size={1} />,
      title: `${listing.garage_spaces} Car Garage`,
    })
  } else if (listing.total_rooms) {
    highlight.push({
      icon: <McIcon path='floor-plan' size={1} />,
      title: `${listing.total_rooms} Total Rooms`,
    })
  }

  return {
    price: listing.price ? formatCurrency(listing.price) : null,
    description: listing.trimDescription,
    headline: [
      {
        icon: <McIcon path='bed-empty' size={1} />,
        title: `${listing.beds} Beds`,
      },
      {
        icon: <McIcon path='bathtub' size={1} />,
        title: `${listing.totalBaths} Baths`,
      },
      {
        icon: <McIcon path='ruler-square' size={1} />,
        title: `${listing.square_feet} Sqft`,
      },
    ],
    highlight,
    additional: [
      {
        icon: <McIcon path='bed-empty' size={1} />,
        title: listing.beds ? `${listing.beds} Beds` : null,
      },
      {
        icon: <McIcon path='bathtub' size={1} />,
        title: listing.full_baths ? `${listing.full_baths} Baths` : null,
      },
      {
        icon: <McIcon path='toilet' size={1} />,
        title: listing.half_baths ? `${listing.half_baths} Half Baths` : null,
      },
      {
        icon: <McIcon path='human-male-height-variant' size={1} />,
        title: listing.number_of_stories
          ? `${listing.number_of_stories} Stories`
          : null,
      },
      {
        icon: <McIcon path='garage-open-variant' size={1} />,
        title: listing.garage_spaces
          ? `${listing.garage_spaces} Car Garage`
          : null,
      },
      {
        icon: <McIcon path='floor-plan' size={1} />,
        title: listing.total_rooms
          ? `${listing.total_rooms} Total Rooms`
          : null,
      },
    ],
  }
}
