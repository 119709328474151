import { Box, Button, Chip, SxProps, Theme, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Listing } from '../../../../shared/models'
import { useStores } from '../../models'
import { Docs } from './Docs/Docs'
import { useListingAttributes } from './useListingAttributes'

interface HighlightsProps {
  listing: Listing
}

export const Highlights: React.FC<HighlightsProps> = observer(({ listing }) => {
  const { fileStore } = useStores()
  const attributes = useListingAttributes(listing)
  const [descriptionOpen, setDescriptionOpen] = useState(false)
  const files = fileStore.listingFiles({ listing_id: listing.id })

  return (
    <Box>
      <Typography variant='h5' component='div' sx={{ mb: 1 }}>
        Highlights
      </Typography>

      <Box sx={$attributeContainer}>
        {attributes.highlight.map(attr => (
          <Chip
            variant='outlined'
            key={attr.title}
            icon={attr.icon}
            label={attr.title}
            sx={{ mr: 1, mt: 1 }}
          />
        ))}
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography
          variant='body1'
          component='div'
          sx={{ display: 'block', whiteSpace: 'pre', textWrap: 'wrap' }}
        >
          {descriptionOpen ? listing.description : attributes.description.text}
        </Typography>

        {attributes.description.long ? (
          <Button
            variant='text'
            onClick={() => setDescriptionOpen(current => !current)}
          >
            Show {descriptionOpen ? 'Less' : 'More'}
          </Button>
        ) : null}
      </Box>

      {!listing?.accepted_offer && files.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Docs listing={listing} />
        </Box>
      )}
    </Box>
  )
})

const $attributeContainer: SxProps<Theme> = {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
}
