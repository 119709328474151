/**
 * This file imports configuration objects from either the config.dev.js file
 * or the config.prod.js file depending on whether we are in __DEV__ or not.
 *
 * Note that we do not gitignore these files. Unlike on web servers, just because
 * these are not checked into your repo doesn't mean that they are secure.
 * In fact, you're shipping a JavaScript bundle with every
 * config variable in plain text. Anyone who downloads your app can easily
 * extract them.
 *
 * If you doubt this, just bundle your app, and then go look at the bundle and
 * search it for one of your config variable values. You'll find it there.
 *
 * Read more here: https://reactnative.dev/docs/security#storing-sensitive-info
 */
import { getAssets } from '../../../shared/data'
import BaseConfig from './config.base'
import DevConfig from './config.dev'
import ProdConfig from './config.prod'

let ExtraConfig = ProdConfig

if (
  import.meta.env.MODE === 'development' ||
  window.location.host.includes('stage')
) {
  ExtraConfig = DevConfig
}

const config = {
  ...BaseConfig,
  ...ExtraConfig,
  assets: getAssets(ExtraConfig.prod),
}

export default config
