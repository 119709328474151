export enum LocalStoreOp {
  SET = 'set',
  GET = 'get',
  REMOVE = 'remove',
}

export enum LocalStoreKey {
  THEME = 'homy-theme',
  LINK_EMAIL = 'homy-link-email',
}

export function localStore(
  op: LocalStoreOp,
  key: LocalStoreKey,
  value?: string
) {
  let result: string | null = null

  if (op === LocalStoreOp.SET && value) {
    localStorage.setItem(key, value)
  } else if (op === LocalStoreOp.GET) {
    result = localStorage.getItem(key)
  } else if (op === LocalStoreOp.REMOVE) {
    localStorage.removeItem(key)
  }

  return result
}
