import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { withSetPropAction } from "../withSetPropAction"

export const LocationModel = types
  .model("Location")
  .props({
    latitude: types.maybe(types.number),
    longitude: types.maybe(types.number),
    county: types.maybe(types.string),
    city: types.maybe(types.string),
    state: types.maybe(types.string),
    country: types.maybe(types.string),
    zip_code: types.maybe(types.string),
  })
  .actions(withSetPropAction)

export interface Location extends Instance<typeof LocationModel> {}
export interface LocationSnapshot extends SnapshotOut<typeof LocationModel> {}
