import { CardActionArea, SxProps, Theme } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import ImageGallery from 'react-image-gallery'
import { useNavigate } from 'react-router-dom'
import { Listing } from '../../../../shared/models'
import { getVanityAddress } from '../../../../shared/utils'
import { useStores } from '../../models'
import { HeaderAttributes } from '../ListingDetails'
import './listingCardImage.css'

interface ListingCardProps {
  listing: Listing
  sx?: SxProps<Theme>
  mediaHeight?: number
  clickable?: boolean
  raiseOnActive?: boolean
  activeScrollIntoView?: boolean
}

export const ListingCard = observer(
  ({
    listing,
    sx,
    mediaHeight = 240,
    clickable = true,
    raiseOnActive = true,
    activeScrollIntoView = true,
  }: ListingCardProps) => {
    const { listingStore } = useStores()
    const navigate = useNavigate()
    const cardRef = useRef<HTMLDivElement | null>(null)
    const fullImageUrls = listing.imageUrls
    const imgUrl = fullImageUrls.length > 0 ? fullImageUrls[0] : undefined
    const vanityAddress = getVanityAddress(listing.address)
    const isActive = listing.id === listingStore?.activeListing?.id

    useEffect(() => {
      if (activeScrollIntoView && isActive && cardRef.current) {
        cardRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }, [activeScrollIntoView, isActive])

    return (
      <div
        onMouseEnter={() => listingStore.setProp('activeListingId', listing.id)}
        onMouseLeave={() => listingStore.setProp('activeListingId', null)}
      >
        <Card sx={sx} raised={raiseOnActive && isActive} ref={cardRef}>
          {clickable ? (
            <>
              {imgUrl ? (
                <ImageGallery
                  items={fullImageUrls.map(img => ({
                    original: img,
                    thumbnail: img,
                  }))}
                  showPlayButton={false}
                  showIndex={false}
                  showThumbnails={false}
                  showFullscreenButton={false}
                  showBullets={fullImageUrls.length > 1}
                  showNav={false}
                  additionalClass='listing-card'
                  onClick={() =>
                    navigate(`/listings/${vanityAddress}/${listing.id}`)
                  }
                />
              ) : null}
              <CardActionArea
                onClick={() =>
                  navigate(`/listings/${vanityAddress}/${listing.id}`)
                }
              >
                <CardContent>
                  <HeaderAttributes listing={listing} card />
                </CardContent>
              </CardActionArea>
            </>
          ) : (
            <>
              {imgUrl ? (
                <CardMedia
                  sx={{ height: mediaHeight }}
                  image={imgUrl}
                  title={listing.address}
                />
              ) : null}

              <CardContent>
                <HeaderAttributes listing={listing} card />
              </CardContent>
            </>
          )}
        </Card>
      </div>
    )
  }
)
