import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { listingsFileSignedUrl } from "../../services"
import { public_file_url } from "../../utils"
import { withSetPropAction } from "../withSetPropAction"
import { FilePrevVersionModel } from "./FilePrevVersion"

export enum FileStatus {
  PUBLIC = "public",
  PRIVATE = "private",
  SHARED = "shared",
}

export const fileExtMap = {
  "image/png": "png",
  "image/jpeg": "jpg",
  "image/webp": "webp",
  "image/heic": "heic",
  "image/heif": "heif",
  "application/pdf": "pdf",
}

export const FileModel = types
  .model("File")
  .props({
    id: types.identifier,
    created_at: types.Date,
    updated_at: types.maybeNull(types.Date),
    listing_id: types.maybeNull(types.string),
    user_id: types.string,
    name: types.string,
    file_name: types.string,
    bucket: types.string,
    path: types.string,
    mimetype: types.string,
    size: types.number,
    status: types.enumeration("FileStatus", ["public", "private", "shared"]),
    type: types.maybeNull(types.string),
    etag: types.maybeNull(types.string),
    md5_hash: types.maybeNull(types.string),
    image: types.maybeNull(types.boolean),
    url: types.maybeNull(types.string),
    prev_versions: types.maybeNull(types.array(FilePrevVersionModel)),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    async getUrl() {
      const result: { error: string | null; url?: string } = { error: null }

      if (store.status === FileStatus.PUBLIC) {
        result.url = public_file_url({
          bucket: store.bucket,
          path: store.path,
        })
      } else if (store.listing_id) {
        const { data, error } = await listingsFileSignedUrl({
          path: { id: store.listing_id, file_id: store.id },
          query: { valid_minutes: 5 },
        })

        if (error) {
          if (error.detail) {
            result.error = Array.isArray(error.detail)
              ? error.detail.map((e) => e.msg).join(", ")
              : error.detail
          } else {
            result.error = "Failed to get signed url"
          }
        } else if (data) {
          result.url = data.url
        }
      }

      return result
    },
  }))
  .views((store) => ({
    get extension() {
      // @ts-ignore setting to null if nout found
      const ext: string | null = fileExtMap[store.mimetype] ?? null
      return ext
    },
  }))

export interface File extends Instance<typeof FileModel> {}
export interface FileSnapshotOut extends SnapshotOut<typeof FileModel> {}
export interface FileSnapshotIn extends SnapshotIn<typeof FileModel> {}
