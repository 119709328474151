import { DateTime } from "luxon"
import { Instance, SnapshotOut, applySnapshot, types } from "mobx-state-tree"
import { notificationsAckNotifications, notificationsGetNotifications } from "../../services"
import { withSetPropAction } from "../withSetPropAction"
import { Notification, NotificationModel } from "./Notification"

export interface NotificationData {
  type?: string
  screen?: string
  active_id?: string
  secondary_id?: string
}

export const NotificationStoreModel = types
  .model("NotificationStore")
  .props({
    notifications: types.array(NotificationModel),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    reset() {
      applySnapshot(store, {})
    },
  }))
  .actions((store) => ({
    addNotification(doc: Notification) {
      store.notifications.push(doc)
    },
    addNotifications(notifications: Notification[]) {
      store.notifications.push(...notifications)
    },
    removeNotification(doc: Notification) {
      store.notifications.remove(doc)
    },
  }))
  .actions((store) => ({
    async fetchNotifications({ limit = 50 }: { limit?: number }) {
      const result: { error: string | null; errorDetails?: string | null } = { error: null }

      const { error, data } = await notificationsGetNotifications({ query: { limit } })

      if (error) {
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e: any) => e.msg).join(", ")
          : error.detail

        result.error = "Failed to fetch notifications"
        return result
      }

      if (data) {
        const notificationData = data.map((notification) => {
          return NotificationModel.create({
            ...notification,
            ...notification.data,
            created_at: DateTime.fromISO(notification.created_at, { zone: "utc" }).toJSDate(),
            updated_at: notification.updated_at
              ? DateTime.fromISO(notification.updated_at, { zone: "utc" }).toJSDate()
              : null,
          })
        })

        store.setProp("notifications", notificationData)
      }

      return result
    },
  }))
  .views((store) => ({
    get sortedNotifications() {
      return store.notifications
        .filter((l) => !l.ack)
        .sort((a, b) => (a.created_at === b.created_at ? 0 : a.created_at > b.created_at ? -1 : 1))
    },
    get realtimeNotification() {
      return store.notifications.find((l) => l.realtime && !l.ack)
    },
    notificationById(id: string) {
      return store.notifications.find((n) => n.id === id)
    },
    listingPendingChats(listing_id: string) {
      const channels: { id: string; buyer_id: string }[] = []
      for (const n of store.notifications) {
        if (!n.ack && n.type === "chat" && n.active_id === listing_id && n.secondary_id) {
          channels.push({ id: n.id, buyer_id: n.secondary_id })
        }
      }

      return channels
    },
  }))
  .actions((store) => ({
    async ackNotifications({ ids }: { ids: string[] }) {
      const result: { error: string | null; errorDetails?: string | null } = { error: null }

      const { error } = await notificationsAckNotifications({ body: { ids } })

      if (error) {
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e: any) => e.msg).join(", ")
          : error.detail

        result.error = "Failed to ack notifications"
      } else {
        for (const notify of store.notifications) {
          if (ids.includes(notify.id)) {
            notify.setProp("ack", true)
          }
        }
      }

      return result
    },
  }))

export interface NotificationStore extends Instance<typeof NotificationStoreModel> {}
export interface NotificationStoreSnapshot extends SnapshotOut<typeof NotificationStoreModel> {}
