import {
  Avatar,
  Modal as BaseModal,
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  SxProps,
  Theme,
  styled,
  useMediaQuery,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { McIcon } from '../../Components'
import { useApiCallState } from '../../hooks'
import { useStores } from '../../models'
import { useTheme } from '../../theme'

interface FundsCardProps {
  sx?: SxProps<Theme>
}

export const FundsCard: React.FC<FundsCardProps> = observer(({ sx }) => {
  const { accountStore } = useStores()
  const { loading, setLoading, setMsg } = useApiCallState()
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [docUrl, setDocUrl] = useState<string | null>(null)
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.up('md'))

  async function handleAction(action: 'open' | 'delete') {
    setLoading(true)

    if (action === 'open') {
      const res = await accountStore.user.checkFunds({ include_url: true })
      if (res.error) {
        setMsg(res.error, 'error')
      } else if (res.url) {
        if (isMedium) {
          setDocUrl(res.url)
        } else {
          setDocUrl(null)
          window.open(res.url, '_blank')
        }
      }
    } else {
      const res = await accountStore.user.deleteFundsDoc()
      if (res.error) {
        setMsg(res.error, 'error')
      } else {
        setMsg('Document deleted', 'success')
      }
    }

    setLoading(false)
  }

  return (
    <>
      <Box sx={sx}>
        <Card sx={{ mt: theme.spacing(1), alignItems: 'center' }}>
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                <McIcon path='cash-check' />
              </Avatar>
            }
            title='Proof of Funds'
            action={
              loading ? (
                <CircularProgress size={30} sx={{ mr: theme.spacing(1) }} />
              ) : (
                <>
                  <IconButton
                    onClick={() => setConfirmDelete(true)}
                    sx={{ mr: theme.spacing(1) }}
                  >
                    <McIcon path='delete' size={1} />
                  </IconButton>
                  <IconButton
                    onClick={() => handleAction('open')}
                    sx={{ mr: theme.spacing(1) }}
                  >
                    <McIcon path='eye' size={1} />
                  </IconButton>
                </>
              )
            }
          />
        </Card>
        {confirmDelete && (
          <Dialog
            open={confirmDelete}
            onClose={() => setConfirmDelete(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              {'Are you sure you want to delete your Proof of Funds Document?'}
            </DialogTitle>
            <DialogActions>
              <Button onClick={() => setConfirmDelete(false)}>No</Button>
              <Button
                onClick={() => {
                  handleAction('delete')
                  setConfirmDelete(false)
                }}
                autoFocus
                color='error'
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>

      {isMedium && docUrl && (
        <Modal open={!!docUrl} onClose={() => setDocUrl(null)}>
          <iframe
            src={docUrl}
            style={{
              width: '80%',
              height: '90%',
            }}
          />
        </Modal>
      )}
    </>
  )
})

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
