import { DateTime } from "luxon"
import { Instance, SnapshotOut, applySnapshot, types } from "mobx-state-tree"
import { assetsAssetUrl, offersGetOffers, offersSubmitOffer } from "../../services"
import { withSetPropAction } from "../withSetPropAction"
import { Offer, OfferModel, OfferStatus } from "./Offer"

export const OfferStoreModel = types
  .model("OfferStore")
  .props({
    offers: types.array(OfferModel),
    activeOfferId: types.maybeNull(types.string),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    reset() {
      applySnapshot(store, {})
    },
    addOffer(doc: Offer) {
      store.offers.push(doc)
    },
    deleteOffer(doc: Offer) {
      store.offers.remove(doc)
    },
  }))
  .actions((store) => ({
    async fetchOffers({ listing_id }: { listing_id?: string }) {
      const result: { error: string | null; errorDetails?: string | null; buyerIds: string[] } = {
        error: null,
        buyerIds: [],
      }

      const { error, data } = await offersGetOffers({
        query: {
          listing_id,
          limit: 100,
        },
      })

      if (error) {
        result.error = "Failed to fetch offers"
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e) => e.msg).join(", ")
          : error.detail

        return result
      } else {
        const userIds: string[] = []
        for (const record of data) {
          if (!userIds.includes(record.user_id)) {
            userIds.push(record.user_id)
          }
        }
        result.buyerIds = userIds

        const newData = data.map((offer) => {
          return OfferModel.create({
            ...offer,
            status: offer.status,
            created_at: DateTime.fromISO(offer.created_at, { zone: "utc" }).toJSDate(),
            updated_at: offer.updated_at
              ? DateTime.fromISO(offer.updated_at, { zone: "utc" }).toJSDate()
              : null,
          })
        })

        if (listing_id) {
          const dataUpdated = [
            ...store.offers.filter((item) => item.listing_id !== listing_id),
            ...newData,
          ]
          store.setProp("offers", dataUpdated)
        } else {
          store.setProp("offers", newData)
        }
      }

      return result
    },
  }))
  .actions(() => ({
    async submitOffer({
      listing_id,
      doc,
      price,
      earnest,
      dueDilligence,
      agentCommission,
    }: {
      listing_id: string
      doc: File
      price: number
      earnest: number
      dueDilligence: number
      agentCommission: number
    }) {
      const result: { error: string | null; errorDetails?: string | null } = {
        error: "Failed to submit offer",
      }

      const { error } = await offersSubmitOffer({
        body: {
          listing_id,
          price: Math.round(price),
          earnest: Math.round(earnest),
          due_dilligence: Math.round(dueDilligence),
          agent_commission: Math.round(agentCommission),
          offer_file: doc,
        },
      })

      if (error) {
        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e) => e.msg).join(", ")
          : error.detail
      } else {
        result.error = null
      }

      return result
    },
  }))
  .views((store) => ({
    listingUserOffers(listing_id?: string) {
      const allOffers = store.offers
        .slice()
        .sort((a, b) => (a.created_at === b.created_at ? 0 : a.created_at > b.created_at ? -1 : 1))

      const offers = listing_id ? allOffers.filter((l) => l.listing_id === listing_id) : allOffers

      const users: string[] = []
      const userOffers: Offer[] = []
      for (const record of offers) {
        if (!users.includes(record.user_id)) {
          users.push(record.user_id)
          userOffers.push(record)
        }
      }

      return userOffers
    },
    latestListingOffer(listing_id: string) {
      const sortedOffers = store.offers
        .filter((l) => l.listing_id === listing_id)
        .sort((a, b) => (a.created_at === b.created_at ? 0 : a.created_at > b.created_at ? -1 : 1))
      return sortedOffers.length > 0 ? sortedOffers[0] : null
    },
    async getBlankContractUrl() {
      const result: { error: string | null; errorDetails?: string | null; url?: string } = {
        error: null,
      }

      const { data, error } = await assetsAssetUrl({ path: { asset: "nc_offer_contract" } })

      if (error) {
        result.error = "Failed to get contract"

        result.errorDetails = Array.isArray(error.detail)
          ? error.detail.map((e) => e.msg).join(", ")
          : error.detail
      } else if (data) {
        result.url = data.url
      }

      return result
    },
  }))
  .views((store) => ({
    get activeOffer() {
      if (!store.activeOfferId) return undefined
      return store.offers.find((l) => l.id === store.activeOfferId)
    },
    offerById(id: string) {
      return store.offers.find((o) => o.id === id)
    },
    pendingOffers(listing_id?: string) {
      const pendingOffers = store
        .listingUserOffers(listing_id)
        .filter((l) => l.status === OfferStatus.ACTIVE || l.status === OfferStatus.COUNTERED)

      return listing_id ? pendingOffers.filter((l) => l.listing_id === listing_id) : pendingOffers
    },
    listingAcceptedOffer(listing_id: string) {
      return store.offers.find(
        (o) => o.listing_id === listing_id && o.status === OfferStatus.ACCEPTED,
      )
    },
    listingAllOffers(listing_id: string) {
      return store.offers
        .filter((l) => l.listing_id === listing_id)
        .sort((a, b) => (a.created_at === b.created_at ? 0 : a.created_at > b.created_at ? -1 : 1))
    },
  }))

export interface OfferStore extends Instance<typeof OfferStoreModel> {}
export interface OfferStoreSnapshot extends SnapshotOut<typeof OfferStoreModel> {}
