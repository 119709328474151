import MenuIcon from '@mui/icons-material/Menu'
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Tooltip,
} from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import pkg from '../../../package.json'
import homyImg from '../../assets/homy.png'
import { useStores } from '../../models'
import { useTheme } from '../../theme'
import { signOutOfApp } from '../../utils/auth'
import { localStore, LocalStoreKey, LocalStoreOp } from '../../utils/storage'
import { McIcon } from '../McIcon'
import { Feedback } from './Feedback'
import { LoginButtons } from './LoginButtons'
import { Notifications } from './Notifications'

const pages = ['Listings']

export const Header = observer(() => {
  const rootStore = useStores()
  const { accountStore } = rootStore
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const navigate = useNavigate()
  const theme = useTheme()
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const profileName = accountStore.user.profileName
  const profilePic = accountStore.user.profile?.pic

  const settings = [
    {
      text: 'Toggle Theme',
      icon: <McIcon path='brightness-6' />,
      onClick: () => {
        localStore(
          LocalStoreOp.SET,
          LocalStoreKey.THEME,
          accountStore.darkMode ? 'light' : 'dark'
        )

        accountStore.setProp('darkMode', !accountStore.darkMode)
      },
    },
    {
      text: 'Feedback',
      icon: <McIcon path='comment-question' />,
      onClick: () => setFeedbackOpen(true),
    },
    {
      text: 'Log Out',
      icon: <McIcon path='logout' />,
      onClick: async () => {
        await signOutOfApp()
        rootStore.reset()
        window.location.reload()
      },
    },
  ]

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          component='nav'
          color={theme.palette.mode === 'light' ? 'inherit' : undefined}
        >
          <Toolbar>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 2 }}>
              <Link to='/'>
                <img src={homyImg} height={32} width={32} />
              </Link>
            </Box>

            <Tooltip title={pkg.version}>
              <Typography
                variant='h6'
                component='div'
                sx={theme => ({
                  display: {
                    xs: 'none',
                    md: 'flex',
                    marginRight: theme.spacing(4),
                  },
                })}
              >
                Homy
              </Typography>
            </Tooltip>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
              }}
            >
              {pages.map(page => (
                <Button
                  key={page}
                  onClick={() => {
                    setAnchorElNav(null)
                    navigate(page.toLowerCase())
                  }}
                  sx={{ my: 2, mr: 2, display: 'block' }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={() => setAnchorElNav(null)}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map(page => (
                  <MenuItem
                    key={page}
                    onClick={() => {
                      setAnchorElNav(null)
                      navigate(page.toLowerCase())
                    }}
                  >
                    <Typography textAlign='center'>{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box
              sx={theme => ({
                display: {
                  xs: 'flex',
                  md: 'none',
                  marginRight: theme.spacing(2),
                },
              })}
            >
              <Link to='/'>
                <img src={homyImg} height={30} width={30} />
              </Link>
            </Box>
            <Typography
              variant='h6'
              component='div'
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
              Homy
            </Typography>

            <Box sx={{ flexGrow: 0 }}>
              {accountStore.user.loggedIn ? (
                <>
                  <Notifications />

                  <Tooltip title='Account'>
                    <IconButton
                      onClick={() => setDrawerOpen(true)}
                      sx={{ p: 0 }}
                    >
                      <Avatar alt={profileName} src={profilePic || undefined} />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Button variant='contained' onClick={() => setDrawerOpen(true)}>
                  Log In
                </Button>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </Box>

      <nav>
        <Drawer
          anchor='right'
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <List sx={{ p: 0, minWidth: '15rem', maxWidth: '22rem' }}>
            {accountStore.user.loggedIn ? (
              <>
                <ListItemButton
                  onClick={() => {
                    navigate('account')
                    setDrawerOpen(false)
                  }}
                >
                  {profilePic && (
                    <ListItemAvatar>
                      <Avatar alt={profileName} src={profilePic} />
                    </ListItemAvatar>
                  )}
                  <ListItemText
                    primary={profileName}
                    secondary={accountStore.user.email}
                  />
                </ListItemButton>

                <Divider />

                {settings.map(item => (
                  <ListItem key={item.text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        item.onClick()
                        setDrawerOpen(false)
                      }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </>
            ) : (
              <LoginButtons onClick={() => setDrawerOpen(false)} />
            )}
          </List>
        </Drawer>
      </nav>

      {feedbackOpen && <Feedback handleClose={() => setFeedbackOpen(false)} />}
    </>
  )
})
