import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material'
import { AddToCalendarButton } from 'add-to-calendar-button-react'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import {
  Tour,
  TourStatus,
  TourStatusUpdate,
} from '../../../../../shared/models'
import { getVanityAddress } from '../../../../../shared/utils'
import { McIcon } from '../../../Components'
import { useApiCallState } from '../../../hooks'
import { useStores } from '../../../models'
import { useTheme } from '../../../theme'

const statusColorMap = {
  [TourStatus.APPROVED]: 'success',
  [TourStatus.REQUESTED]: 'primary',
  [TourStatus.CANCELLED]: 'warning',
  [TourStatus.DECLINED]: 'error',
}

interface TourDetailsProps {
  tour: Tour
  newTour: () => void
  address: string
  newOffer: () => void
}

export const TourDetails: React.FC<TourDetailsProps> = observer(
  ({ tour, newTour, address, newOffer }) => {
    const { listingStore } = useStores()
    const theme = useTheme()
    const { loading, setLoading, confirmDeleteId, setConfirmDeleteId, setMsg } =
      useApiCallState()
    const [passcode, setPasscode] = useState('')
    const [instructions, setInstructions] = useState('')
    const [refreshTick, setRefreshTick] = useState(0)

    useEffect(() => {
      const intervalId = setInterval(
        () => setRefreshTick(current => current + 1),
        10 * 1000 // Every 10 seconds
      )

      return () => {
        clearInterval(intervalId)
      }
    }, [])

    // @ts-expect-error Correct type
    const statusColor = statusColorMap[tour.status] || 'primary'

    const now = DateTime.now()
    const startDt = DateTime.fromJSDate(tour.start)
    const endDt = DateTime.fromJSDate(tour.end)
    const startIsoDate = startDt.toISODate()
    const endIsoDate = endDt.toISODate()
    const startTime = startDt.toFormat('HH:mm')
    const endTime = endDt.toFormat('HH:mm')
    const vanityAddress = getVanityAddress(address)
    const icalUid = `${vanityAddress}-${startDt.toUnixInteger()}`

    const passCodeThreshold = startDt.minus({ hours: 1 })
    const passcodeAvailableTime = now > passCodeThreshold && now < endDt

    const activeListing = listingStore.listingById(tour.listing_id)
    if (!activeListing) {
      return null
    }

    async function handleDecrypt() {
      if (!activeListing) return

      setLoading(true)
      const { error, errorDetails, decryptedPasscode, decryptedInstructions } =
        await activeListing.getDecryptedPasscode()

      if (error) {
        console.error(errorDetails || error)
        setMsg(error, 'error')
      } else {
        setPasscode(decryptedPasscode ?? '')
        setInstructions(decryptedInstructions ?? '')
      }

      setLoading(false)
    }

    return (
      <>
        <Card variant='outlined'>
          <CardHeader
            title='Scheduled Tour'
            subheader={
              <>
                <Typography id={`created-${refreshTick}`}>
                  created {DateTime.fromJSDate(tour.created_at).toRelative()}
                </Typography>
                {tour.updated_at && (
                  <Typography variant='body2' id={`updated-${refreshTick}`}>
                    updated {DateTime.fromJSDate(tour.updated_at).toRelative()}
                  </Typography>
                )}
              </>
            }
            action={
              <Chip
                label={tour.status}
                color={statusColor}
                sx={{ mr: theme.spacing(1) }}
              />
            }
          />

          <CardContent>
            <Typography variant='h6'>
              {startDt.toFormat('cccc, LLLL d, h:mm a')} -{' '}
              {endDt.toFormat('h:mm a ZZZZ')}
            </Typography>
            <Typography variant='body2' id={`start-relative-${refreshTick}`}>
              {startDt.toRelative()}
            </Typography>

            {tour.status === TourStatus.REQUESTED && (
              <Typography variant='body1' sx={{ mt: 4 }}>
                Additional information on how to access the property will be
                provided once the seller approves the tour.
              </Typography>
            )}

            {activeListing.passcode &&
              tour.status === TourStatus.APPROVED &&
              now < endDt && (
                <Box sx={{ mt: theme.spacing(2) }}>
                  <Chip
                    icon={
                      loading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <McIcon
                          path={passcode ? 'lock-open-variant' : 'lock'}
                        />
                      )
                    }
                    label={
                      passcode
                        ? passcode
                        : `View Passcode${activeListing.passcodeInstructions ? ' & Instructions' : ''}`
                    }
                    color={passcode ? 'success' : 'warning'}
                    variant='outlined'
                    disabled={!passcodeAvailableTime}
                    onClick={
                      passcodeAvailableTime
                        ? () => {
                            if (passcode) {
                              navigator.clipboard.writeText(passcode)
                              setMsg('Copied passcode', 'success')
                            } else {
                              handleDecrypt()
                            }
                          }
                        : undefined
                    }
                  />

                  {instructions && (
                    <Typography variant='body1' sx={{ mt: theme.spacing(1) }}>
                      {instructions}
                    </Typography>
                  )}

                  {!passcodeAvailableTime && (
                    <Typography
                      variant='body2'
                      sx={{ mt: theme.spacing(1) }}
                      id={`pc-${refreshTick}`}
                    >
                      Passcode available {passCodeThreshold.toRelative()} (
                      {passCodeThreshold.toFormat('h:mm a ZZZZ')})
                    </Typography>
                  )}
                </Box>
              )}
          </CardContent>

          <Divider />

          <CardActions sx={{ overflow: 'visible' }}>
            {startIsoDate && endIsoDate ? (
              <Box sx={{ zIndex: 1000 }}>
                <AddToCalendarButton
                  name={`🏡 Tour ${address}`}
                  options={['Apple', 'Google', 'iCal', 'Outlook.com', 'Yahoo']}
                  location={address}
                  startDate={startIsoDate}
                  endDate={endIsoDate}
                  startTime={startTime}
                  endTime={endTime}
                  timeZone={startDt.toFormat('z')}
                  lightMode={theme.palette.mode}
                  iCalFileName={icalUid}
                  uid={icalUid}
                  forceOverlay
                  hideBranding
                  status={
                    tour.status === TourStatus.APPROVED
                      ? 'CONFIRMED'
                      : tour.status === TourStatus.DECLINED ||
                          tour.status === TourStatus.CANCELLED
                        ? 'CANCELLED'
                        : 'TENTATIVE'
                  }
                />
              </Box>
            ) : null}

            {(tour.status === TourStatus.REQUESTED ||
              tour.status === TourStatus.APPROVED) && (
              <Button color='error' onClick={() => setConfirmDeleteId('tour')}>
                Cancel Tour
              </Button>
            )}

            {(tour.status === TourStatus.DECLINED ||
              tour.status === TourStatus.CANCELLED) && (
              <Button color='primary' onClick={() => newTour()}>
                Request New Tour
              </Button>
            )}

            <Button color='success' onClick={() => newOffer()}>
              Submit Offer
            </Button>
          </CardActions>
        </Card>
        {confirmDeleteId ? (
          <Dialog
            open={!!confirmDeleteId}
            onClose={() => setConfirmDeleteId(null)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              {'Are you sure you want to cancel your tour?'}
            </DialogTitle>
            <DialogActions>
              <Button
                onClick={() => setConfirmDeleteId(null)}
                disabled={loading}
              >
                No
              </Button>
              <Button
                onClick={async () => {
                  setLoading(true)
                  const { error } = await tour.updateTour({
                    status: TourStatusUpdate.CANCELLED,
                  })

                  if (error) {
                    setMsg(error, 'error')
                  } else {
                    tour.setProp('status', TourStatus.CANCELLED)
                    setMsg('Tour cancelled', 'success')
                  }

                  setConfirmDeleteId(null)
                  setLoading(false)
                }}
                autoFocus
                color='error'
                disabled={loading}
              >
                Yes{' '}
                {loading && (
                  <CircularProgress size={24} sx={{ ml: theme.spacing(1) }} />
                )}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </>
    )
  }
)
