/**
 * Get the trailing ID from from a URL param
 */
export function getParamId(param: string) {
  const paramSections = decodeURI(param).split('-')

  const id =
    paramSections && paramSections.length > 0
      ? paramSections.slice(-1)[0]
      : null

  return id
}
