/* Material Design colors
https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
https://callstack.github.io/react-native-paper/docs/guides/theming#creating-dynamic-theme-colors
*/

export const customColors = {
  red500: "#f44336",
  amber500: "#ffc107",
  green500: "#4caf50",
}
