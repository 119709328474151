import {
  Avatar,
  Modal as BaseModal,
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'
import {
  FileStatus,
  MsgStatus,
  OfferStatus,
} from '../../../../../shared/models'
import { listingsAddFileRevision } from '../../../../../shared/services'
import { formatFileSize, timeAgo } from '../../../../../shared/utils'
import { McIcon, VisuallyHiddenInput } from '../../../Components'
import { useApiCallState } from '../../../hooks'
import { useStores } from '../../../models'
import { useTheme } from '../../../theme'

interface DocCardProps {
  docId?: string
  offerId?: string | null
  offerFile?: File | null
  setOfferFile?: (item: File | null) => void
  sx?: SxProps<Theme>
}

export const DocCard: React.FC<DocCardProps> = observer(
  ({ docId, offerId, offerFile, setOfferFile, sx }) => {
    const { fileStore, chatStore, offerStore } = useStores()
    const [docUrl, setDocUrl] = useState<string | null>(null)
    const fileUpload = useRef<HTMLInputElement | null>(null)
    const { loading, setLoading, setMsg } = useApiCallState()
    const [confirmDelete, setConfirmDelete] = useState(false)
    const theme = useTheme()
    const isMedium = useMediaQuery(theme.breakpoints.up('md'))

    const offer = offerId ? offerStore.offerById(offerId) : null

    const doc = docId
      ? fileStore.fileById(docId)
      : offer?.file_id
        ? fileStore.fileById(offer.file_id)
        : null

    const docNameBase = offerFile
      ? offerFile.name
      : offer
        ? 'Offer Contract'
        : doc?.name.replace('.pdf', '')

    const docUserProfile = doc
      ? chatStore.profileByUserId(doc.user_id)
      : undefined
    const docUserPic = docUserProfile?.pic

    const canEdit = offer
      ? offer?.status === OfferStatus.ACCEPTED
      : doc?.status === FileStatus.SHARED

    async function handleOpen() {
      let url: string | undefined

      if (offerFile) {
        url = URL.createObjectURL(offerFile)
      } else {
        if (!doc) return

        setLoading(true)
        const urlRes = await doc.getUrl()
        url = urlRes.url

        if (urlRes.error || !url) {
          console.error(urlRes.error)
          setMsg(`Failed to load ${doc.name}`, MsgStatus.ERROR)
        }
      }

      if (url) {
        if (isMedium) {
          setDocUrl(url)
        } else {
          setDocUrl(null)
          window.open(url, '_blank')
        }
      }

      setLoading(false)
    }

    async function handleUpload(newDoc: File) {
      if (!doc || !doc.listing_id) return

      setLoading(true)

      const { error } = await listingsAddFileRevision({
        path: { id: doc.listing_id, file_id: doc.id },
        body: {
          new_file: newDoc,
        },
      })

      if (error) {
        console.error(error)
        setMsg('Failed to update document', MsgStatus.ERROR)
      } else {
        const docUpdateRes = await fileStore.fetchFiles({
          listing_id: doc.listing_id,
        })

        if (docUpdateRes.error) {
          if (docUpdateRes.error) console.error(docUpdateRes.error)

          setMsg(
            'Failed to refresh after upload, try manually refreshing the page',
            MsgStatus.ERROR
          )
        }
      }

      setLoading(false)
    }

    return (
      <>
        <Box sx={sx}>
          <Card sx={{ mt: theme.spacing(1), alignItems: 'center' }}>
            <CardHeader
              sx={{ py: 1.5 }}
              avatar={
                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                  <McIcon path={offer ? 'file-sign' : 'file-pdf-box'} />
                </Avatar>
              }
              title={
                <Tooltip
                  title={
                    doc?.prev_versions && doc.prev_versions.length > 0
                      ? `${doc.prev_versions.length} Revisions`
                      : undefined
                  }
                >
                  <Typography>
                    {doc?.prev_versions && doc?.prev_versions.length > 0
                      ? `${docNameBase} (${doc?.prev_versions.length})`
                      : docNameBase}
                  </Typography>
                </Tooltip>
              }
              subheader={
                <Box>
                  <Typography
                    variant='subtitle2'
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      alignContent: 'space-between',
                    }}
                  >
                    {formatFileSize(
                      doc ? doc.size : offerFile ? offerFile.size : 0
                    )}{' '}
                    &bull;{'  '}
                    {timeAgo(
                      doc
                        ? (doc.updated_at ?? doc.created_at)
                        : offerFile
                          ? new Date(offerFile.lastModified)
                          : new Date()
                    )}
                    {docUserProfile?.name && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          alignItems: 'center',
                          alignContent: 'space-between',
                        }}
                      >
                        {docUserPic ? (
                          <Avatar
                            alt={docUserProfile.name}
                            src={docUserPic}
                            sx={{ width: 18, height: 18, ml: 1.5, mr: 1 }}
                          />
                        ) : (
                          <>&bull;</>
                        )}
                        {docUserProfile.name}
                      </Box>
                    )}
                  </Typography>
                </Box>
              }
              action={
                loading ? (
                  <CircularProgress size={30} sx={{ mr: theme.spacing(1) }} />
                ) : (
                  <Box sx={{ mt: 1 }}>
                    {offerFile && (
                      <IconButton
                        onClick={() => setConfirmDelete(true)}
                        sx={{ mr: theme.spacing(1) }}
                      >
                        <McIcon path='delete' size={1} />
                      </IconButton>
                    )}
                    {canEdit && (
                      <IconButton
                        onClick={() => fileUpload?.current?.click()}
                        sx={{ mr: theme.spacing(1) }}
                      >
                        <VisuallyHiddenInput
                          ref={fileUpload}
                          type='file'
                          accept='.pdf'
                          multiple={false}
                          onChange={event => {
                            if (
                              event.target.files &&
                              event.target.files?.length > 0
                            ) {
                              const newDoc = event.target.files[0]
                              if (newDoc.type === 'application/pdf') {
                                handleUpload(newDoc)
                              }
                            }
                          }}
                        />
                        <McIcon path='pencil' size={1} />
                      </IconButton>
                    )}

                    <IconButton
                      onClick={() => handleOpen()}
                      sx={{ mr: theme.spacing(1) }}
                    >
                      <McIcon path='eye' size={1} />
                    </IconButton>
                  </Box>
                )
              }
            />
          </Card>

          {confirmDelete && (
            <Dialog
              open={confirmDelete}
              onClose={() => setConfirmDelete(false)}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>
                {`Are you sure you want to delete ${docNameBase}?`}
              </DialogTitle>
              <DialogActions>
                <Button onClick={() => setConfirmDelete(false)}>No</Button>
                <Button
                  onClick={() => {
                    if (setOfferFile) setOfferFile(null)
                    setConfirmDelete(false)
                  }}
                  autoFocus
                  color='error'
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Box>

        {isMedium && docUrl && (
          <Modal open={!!docUrl} onClose={() => setDocUrl(null)}>
            <iframe
              src={docUrl}
              style={{
                width: '90%',
                height: '90%',
              }}
            />
          </Modal>
        )}
      </>
    )
  }
)

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
