/**
 * Get a Google Map link
 */
export function mapLink({
  latitude,
  longitude,
  place_id,
  streetView,
  web,
}: {
  latitude: number
  longitude: number
  place_id?: string
  streetView?: boolean
  web?: boolean
}) {
  const url = new URL(`https://www.google.com/maps/${streetView ? "@" : "search/"}`)
  url.searchParams.append("api", "1")

  if (streetView) {
    if (web) {
      return `https://maps.google.com/maps?q=&layer=c&cbll=${latitude},${longitude}&cbp=11,0,0,0,0`
    }
    url.searchParams.append("map_action", "pano")
    url.searchParams.append("viewpoint", `${latitude},${longitude}`)
  } else {
    url.searchParams.append("query", `${latitude},${longitude}`)
    if (place_id) {
      url.searchParams.append("query_place_id", place_id)
    }
  }

  return url.href
}
