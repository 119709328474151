import { Card, CardContent } from '@mui/material'
import axios from 'axios'
import { marked } from 'marked'
import { useEffect, useState } from 'react'

marked.use({
  extensions: [
    {
      name: 'link',
      renderer({ href, title, text }) {
        return `<a target="_blank" href="${href}" title="${title}">${text}</a>`
      },
    },
  ],
})

export const NextSteps = () => {
  const [text, setText] = useState('')

  useEffect(() => {
    async function getNextSteps() {
      const result = await axios.get('/next-steps.md')
      const parsedMd = await marked.parse(result.data)
      setText(parsedMd)
    }

    getNextSteps()
  }, [])

  return <div dangerouslySetInnerHTML={{ __html: text }} />
  return (
    <Card variant='outlined'>
      <CardContent>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </CardContent>
    </Card>
  )
}
