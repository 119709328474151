/**
 * These are configuration settings for the production environment.
 */
export default {
  prod: true,
  sentryActive: true,
  analyticsActive: true,
  redirectUrl: 'https://homy.homes',
  apiUrl: 'https://api.homy.homes',
  rtHost: 'wss://rt.homy.homes',
}
