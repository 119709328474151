/* eslint-disable @typescript-eslint/no-empty-object-type */
import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import {
  AccountStoreModel,
  ChatStoreModel,
  FileStoreModel,
  ListingStoreModel,
  NotificationStoreModel,
  OfferStoreModel,
  TourStoreModel,
} from '../../../shared/models'

/**
 * A RootStore model.
 */
export const RootStoreModel = types
  .model('RootStore')
  .props({
    accountStore: types.optional(AccountStoreModel, {}),
    listingStore: types.optional(ListingStoreModel, {}),
    offerStore: types.optional(OfferStoreModel, {}),
    tourStore: types.optional(TourStoreModel, {}),
    chatStore: types.optional(ChatStoreModel, {}),
    notificationStore: types.optional(NotificationStoreModel, {}),
    fileStore: types.optional(FileStoreModel, {}),
  })
  .actions(store => ({
    reset() {
      Object.keys(store).forEach(key => {
        // @ts-expect-error Allow resetting root store
        if (store[key].reset) store[key].reset()
      })
    },
  }))

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}
/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
