import {
  mdiAccountCash,
  mdiAccountCircle,
  mdiAccountGroup,
  mdiAirConditioner,
  mdiAirHumidifier,
  mdiAnvil,
  mdiApple,
  mdiArch,
  mdiArchive,
  mdiBalcony,
  mdiBasketballHoop,
  mdiBathtub,
  mdiBedEmpty,
  mdiBell,
  mdiBilliards,
  mdiBookshelf,
  mdiBrightness6,
  mdiBus,
  mdiCalendarPlus,
  mdiCancel,
  mdiCard,
  mdiCardAccountDetails,
  mdiCashCheck,
  mdiCashFast,
  mdiCashMultiple,
  mdiCastle,
  mdiCeilingFan,
  mdiCeilingLight,
  mdiCertificate,
  mdiChartTree,
  mdiChatQuestion,
  mdiCheckCircle,
  mdiCity,
  mdiCityVariantOutline,
  mdiClose,
  mdiCloseCircleOutline,
  mdiCloseThick,
  mdiCloudUpload,
  mdiCommentQuestion,
  mdiContentSave,
  mdiCupWater,
  mdiCupboard,
  mdiDelete,
  mdiDeskLamp,
  mdiDeskphone,
  mdiDishwasher,
  mdiDockBottom,
  mdiDotsHorizontalCircle,
  mdiDownloadCircle,
  mdiDumbbell,
  mdiEarth,
  mdiElevator,
  mdiEye,
  mdiFan,
  mdiFence,
  mdiFerry,
  mdiFile,
  mdiFilePdfBox,
  mdiFileSign,
  mdiFireplace,
  mdiFleurDeLis,
  mdiFloorPlan,
  mdiFlower,
  mdiFridge,
  mdiFridgeIndustrial,
  mdiGarage,
  mdiGarageOpen,
  mdiGarageOpenVariant,
  mdiGarageVariant,
  mdiGasCylinder,
  mdiGate,
  mdiGoogle,
  mdiGoogleStreetView,
  mdiGrass,
  mdiGreenhouse,
  mdiGrill,
  mdiHammerWrench,
  mdiHexagon,
  mdiHome,
  mdiHomeCity,
  mdiHomeCityOutline,
  mdiHomeClock,
  mdiHomeExportOutline,
  mdiHomeFloor1,
  mdiHomeFloor2,
  mdiHomeGroup,
  mdiHomeModern,
  mdiHomeRoof,
  mdiHomeVariant,
  mdiHotTub,
  mdiHumanGreeting,
  mdiHumanMaleHeightVariant,
  mdiHumanWheelchair,
  mdiHvac,
  mdiImageFilterHdr,
  mdiInformation,
  mdiLanConnect,
  mdiLayers,
  mdiLightningBolt,
  mdiLock,
  mdiLockOpenVariant,
  mdiLogin,
  mdiLoginVariant,
  mdiLogout,
  mdiMapMarkerRadius,
  mdiMicrowave,
  mdiOil,
  mdiParking,
  mdiPencil,
  mdiPier,
  mdiPillar,
  mdiPineTree,
  mdiPineTreeBox,
  mdiPool,
  mdiPropaneTank,
  mdiPyramid,
  mdiRadiator,
  mdiRecordPlayer,
  mdiRectangle,
  mdiRectangleOutline,
  mdiRoadVariant,
  mdiRulerSquare,
  mdiRvTruck,
  mdiSecurity,
  mdiSend,
  mdiShoePrint,
  mdiSilverwareForkKnife,
  mdiSnowflake,
  mdiSofa,
  mdiSolarPanel,
  mdiSpa,
  mdiSprinklerVariant,
  mdiSquare,
  mdiSquareOutline,
  mdiSquareRounded,
  mdiStairsDown,
  mdiSunCompass,
  mdiSunThermometerOutline,
  mdiTableChair,
  mdiTableLarge,
  mdiTelevisionGuide,
  mdiTennis,
  mdiTextureBox,
  mdiToasterOven,
  mdiToilet,
  mdiTools,
  mdiToyBrick,
  mdiTrashCanOutline,
  mdiTree,
  mdiTriangle,
  mdiTumbleDryer,
  mdiUpload,
  mdiViewGridPlus,
  mdiWalk,
  mdiWall,
  mdiWardrobe,
  mdiWarehouse,
  mdiWashingMachine,
  mdiWater,
  mdiWeatherNight,
  mdiWeatherSunny,
  mdiWheelchairAccessibility,
  mdiWindowClosed,
} from '@mdi/js'

const mapping = {
  'account-cash': mdiAccountCash,
  'account-circle': mdiAccountCircle,
  'account-group': mdiAccountGroup,
  apple: mdiApple,
  'air-conditioner': mdiAirConditioner,
  'air-humidifier': mdiAirHumidifier,
  anvil: mdiAnvil,
  arch: mdiArch,
  archive: mdiArchive,
  balcony: mdiBalcony,
  'basketball-hoop': mdiBasketballHoop,
  bathtub: mdiBathtub,
  billiards: mdiBilliards,
  bookshelf: mdiBookshelf,
  bell: mdiBell,
  'bed-empty': mdiBedEmpty,
  'brightness-6': mdiBrightness6,
  bus: mdiBus,
  cancel: mdiCancel,
  'cash-fast': mdiCashFast,
  'cash-check': mdiCashCheck,
  'cash-multiple': mdiCashMultiple,
  card: mdiCard,
  'card-account-details': mdiCardAccountDetails,
  'calendar-plus': mdiCalendarPlus,
  castle: mdiCastle,
  certificate: mdiCertificate,
  'ceiling-fan': mdiCeilingFan,
  'ceiling-light': mdiCeilingLight,
  'chart-tree': mdiChartTree,
  'chat-question': mdiChatQuestion,
  'check-circle': mdiCheckCircle,
  city: mdiCity,
  'city-variant-outline': mdiCityVariantOutline,
  close: mdiClose,
  'close-circle-outline': mdiCloseCircleOutline,
  'close-thick': mdiCloseThick,
  'cloud-upload': mdiCloudUpload,
  'comment-question': mdiCommentQuestion,
  'cup-water': mdiCupWater,
  'content-save': mdiContentSave,
  cupboard: mdiCupboard,
  'desk-lamp': mdiDeskLamp,
  delete: mdiDelete,
  deskphone: mdiDeskphone,
  dishwasher: mdiDishwasher,
  'dock-bottom': mdiDockBottom,
  'dots-horizontal-circle': mdiDotsHorizontalCircle,
  'download-circle': mdiDownloadCircle,
  dumbbell: mdiDumbbell,
  earth: mdiEarth,
  elevator: mdiElevator,
  eye: mdiEye,
  fan: mdiFan,
  fence: mdiFence,
  ferry: mdiFerry,
  'file-pdf-box': mdiFilePdfBox,
  file: mdiFile,
  fireplace: mdiFireplace,
  'fleur-de-lis': mdiFleurDeLis,
  'floor-plan': mdiFloorPlan,
  flower: mdiFlower,
  fridge: mdiFridge,
  'fridge-industrial': mdiFridgeIndustrial,
  'file-sign': mdiFileSign,
  garage: mdiGarage,
  'garage-open': mdiGarageOpen,
  'garage-variant': mdiGarageVariant,
  'garage-open-variant': mdiGarageOpenVariant,
  'gas-cylinder': mdiGasCylinder,
  gate: mdiGate,
  grass: mdiGrass,
  greenhouse: mdiGreenhouse,
  google: mdiGoogle,
  grill: mdiGrill,
  'hammer-wrench': mdiHammerWrench,
  hexagon: mdiHexagon,
  home: mdiHome,
  'home-city': mdiHomeCity,
  'home-city-outline': mdiHomeCityOutline,
  'home-clock': mdiHomeClock,
  'home-export-outline': mdiHomeExportOutline,
  'home-floor-1': mdiHomeFloor1,
  'home-floor-2': mdiHomeFloor2,
  'home-group': mdiHomeGroup,
  'home-modern': mdiHomeModern,
  'home-roof': mdiHomeRoof,
  'home-variant': mdiHomeVariant,
  'hot-tub': mdiHotTub,
  'human-greeting': mdiHumanGreeting,
  'human-wheelchair': mdiHumanWheelchair,
  'human-male-height-variant': mdiHumanMaleHeightVariant,
  hvac: mdiHvac,
  information: mdiInformation,
  'image-filter-hdr': mdiImageFilterHdr,
  'lan-connect': mdiLanConnect,
  layers: mdiLayers,
  'lightning-bolt': mdiLightningBolt,
  lock: mdiLock,
  'lock-open-variant': mdiLockOpenVariant,
  login: mdiLogin,
  'login-variant': mdiLoginVariant,
  logout: mdiLogout,
  'map-marker-radius': mdiMapMarkerRadius,
  microwave: mdiMicrowave,
  oil: mdiOil,
  parking: mdiParking,
  pencil: mdiPencil,
  pier: mdiPier,
  pillar: mdiPillar,
  'pine-tree': mdiPineTree,
  'pine-tree-box': mdiPineTreeBox,
  pool: mdiPool,
  'propane-tank': mdiPropaneTank,
  pyramid: mdiPyramid,
  radiator: mdiRadiator,
  'record-player': mdiRecordPlayer,
  rectangle: mdiRectangle,
  'rectangle-outline': mdiRectangleOutline,
  'road-variant': mdiRoadVariant,
  'rv-truck': mdiRvTruck,
  'ruler-square': mdiRulerSquare,
  security: mdiSecurity,
  send: mdiSend,
  'shoe-print': mdiShoePrint,
  'silverware-fork-knife': mdiSilverwareForkKnife,
  snowflake: mdiSnowflake,
  sofa: mdiSofa,
  'solar-panel': mdiSolarPanel,
  spa: mdiSpa,
  'sprinkler-variant': mdiSprinklerVariant,
  square: mdiSquare,
  'square-outline': mdiSquareOutline,
  'square-rounded': mdiSquareRounded,
  'stairs-down': mdiStairsDown,
  'sun-compass': mdiSunCompass,
  'sun-thermometer-outline': mdiSunThermometerOutline,
  toilet: mdiToilet,
  'table-chair': mdiTableChair,
  'table-large': mdiTableLarge,
  'television-guide': mdiTelevisionGuide,
  tennis: mdiTennis,
  'texture-box': mdiTextureBox,
  'toaster-oven': mdiToasterOven,
  tools: mdiTools,
  'toy-brick': mdiToyBrick,
  'trash-can-outline': mdiTrashCanOutline,
  tree: mdiTree,
  triangle: mdiTriangle,
  'tumble-dryer': mdiTumbleDryer,
  upload: mdiUpload,
  'view-grid-plus': mdiViewGridPlus,
  walk: mdiWalk,
  wall: mdiWall,
  wardrobe: mdiWardrobe,
  warehouse: mdiWarehouse,
  'washing-machine': mdiWashingMachine,
  water: mdiWater,
  'weather-night': mdiWeatherNight,
  'weather-sunny': mdiWeatherSunny,
  'wheelchair-accessibility': mdiWheelchairAccessibility,
  'window-closed': mdiWindowClosed,
  'google-street-view': mdiGoogleStreetView,
}

export type IconMapping = keyof typeof mapping
export function getIconPath(name: IconMapping) {
  return mapping[name]
}
