import { createTheme } from '@mui/material/styles'
import { useStores } from './models'

const root = document.getElementById('root')

const components = {
  MuiPopover: {
    defaultProps: {
      container: root,
    },
  },
  MuiPopper: {
    defaultProps: {
      container: root,
    },
  },
  MuiDialog: {
    defaultProps: {
      container: root,
    },
  },
  MuiModal: {
    defaultProps: {
      container: root,
    },
  },
}

const dark = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: 'rgba(208, 188, 255, 1)',
    },
    secondary: {
      main: 'rgba(204, 194, 220, 1)',
    },
    error: {
      main: 'rgba(242, 184, 181, 1)',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  components,
})

const light = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: 'rgba(103, 80, 164, 1)',
    },
    secondary: {
      main: 'rgba(98, 91, 113, 1)',
    },
    error: {
      main: 'rgba(179, 38, 30, 1)',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  components,
})

export const useTheme = () => {
  const { accountStore } = useStores()
  const appTheme = accountStore.darkMode ? dark : light

  return appTheme
}
