import {
  Modal as BaseModal,
  Box,
  Chip,
  CircularProgress,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'
import { Listing, MsgStatus } from '../../../../../shared/models'
import { FileStatus } from '../../../../../shared/models/Files'
import { listingsAddFile } from '../../../../../shared/services'
import { McIcon, VisuallyHiddenInput } from '../../../Components'
import { useApiCallState } from '../../../hooks'
import { useStores } from '../../../models'
import { useTheme } from '../../../theme'
import { DocCard } from './DocCard'

interface DocsProps {
  listing: Listing
  shared?: boolean
}

export const Docs: React.FC<DocsProps> = observer(({ listing, shared }) => {
  const { fileStore } = useStores()
  const [docUrl, setDocUrl] = useState<string | null>(null)
  const { loading, setLoading, setMsg } = useApiCallState()
  const fileUpload = useRef<HTMLInputElement | null>(null)
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.up('md'))
  const docs = fileStore.listingFiles({
    listing_id: listing.id,
    status: shared ? FileStatus.SHARED : FileStatus.PUBLIC,
    images: false,
  })

  async function handleUpload(docs: File[]) {
    setLoading(true)

    const errors: string[] = []
    await Promise.all(
      docs.map(async doc => {
        const { error } = await listingsAddFile({
          path: { id: listing.id },
          body: {
            name: doc.name,
            new_file: doc,
            status: FileStatus.SHARED,
          },
        })

        if (error) {
          console.error(error)

          const errorDetails = Array.isArray(error.detail)
            ? error.detail.map(e => e.msg).join(', ')
            : error.detail

          if (errorDetails) {
            errors.push(errorDetails)
          }
        }
      })
    )

    if (errors.length > 0) {
      console.error(errors)
      setMsg(
        `Failed to upload ${errors.length} file${errors.length > 1 ? 's' : ''}`,
        MsgStatus.ERROR
      )

      if (errors.length === docs.length) {
        return
      }
    }

    const fetchRes = await fileStore.fetchFiles({ listing_id: listing.id })
    if (fetchRes.error) {
      console.error(fetchRes.errorDetails ?? fetchRes.error)
      setMsg(fetchRes.error, MsgStatus.ERROR)
    }

    setMsg(`File${docs.length > 1 ? 's' : ''} uploaded`, MsgStatus.SUCCESS)

    setLoading(false)
  }

  return (
    <>
      {shared ? (
        <Typography variant='h5' sx={{ mb: 2 }}>
          Shared Documents ({docs.length})
          {loading ? (
            <CircularProgress size={20} sx={{ ml: theme.spacing(1) }} />
          ) : (
            <Tooltip title='Share file with owner'>
              <>
                <Chip
                  label='Upload File'
                  variant='outlined'
                  size='small'
                  icon={<McIcon path='upload' size={0.75} />}
                  onClick={() => fileUpload?.current?.click()}
                  sx={{ ml: 1 }}
                />

                <VisuallyHiddenInput
                  ref={fileUpload}
                  type='file'
                  accept='.pdf'
                  multiple
                  onChange={event => {
                    if (event.target.files && event.target.files?.length > 0) {
                      const docs: File[] = []

                      for (const entry of event.target.files) {
                        if (entry.type === 'application/pdf') {
                          docs.push(entry)
                        }
                      }

                      handleUpload(docs)
                    }
                  }}
                />
              </>
            </Tooltip>
          )}
        </Typography>
      ) : (
        <Typography variant='h5' sx={{ mb: 1 }}>
          Documents ({docs.length})
        </Typography>
      )}

      <Box sx={{ maxHeight: 400, overflowY: 'scroll', pb: 1 }}>
        {docs.map(doc => (
          <DocCard key={doc.id} docId={doc.id} />
        ))}
      </Box>

      {isMedium && docUrl && (
        <Modal open={!!docUrl} onClose={() => setDocUrl(null)}>
          <iframe
            src={docUrl}
            style={{
              width: '90%',
              height: '90%',
            }}
          />
        </Modal>
      )}
    </>
  )
})

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
