import * as Sentry from '@sentry/react'
import * as ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'
import App from './App'
import config from './config'
import './index.css'

if (config.analyticsActive) {
  ReactGA.initialize('G-WZXQWKZ912')
}

if (config.sentryActive) {
  Sentry.init({
    dsn: 'https://a2d34eb842f1fa36a545338f940fc1ed@o4506565450792960.ingest.us.sentry.io/4507329976729600',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/api\.homy\.market\/rest/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

ReactDOM.createRoot(document.getElementById('root')!).render(<App />)
