import { types } from "mobx-state-tree"
import { withSetPropAction } from "../withSetPropAction"

export const TeamMemberModel = types.model("TeamMember").props({
  created_at: types.Date,
  role: types.enumeration("Role", ["owner", "member"]),
  user_id: types.identifier,
  name: types.maybeNull(types.string),
  pic: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
})

export const TeamModel = types
  .model("Team")
  .props({
    id: types.identifier,
    name: types.string,
    description: types.maybeNull(types.string),
    user_id: types.string,
    created_at: types.Date,
    members: types.optional(types.array(TeamMemberModel), []),
  })
  .actions(withSetPropAction)
