/**
 * Generate a URL ID for an address
 */
export function getVanityAddress(address: string) {
  return encodeURI(
    address
      .toLowerCase()
      // @ts-ignore
      .replaceAll(",", "")
      .replaceAll(".", "")
      .replaceAll(" ", "-")
      .replace("-usa", ""),
  )
}
