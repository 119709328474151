import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import * as React from 'react'

interface SectionHeadlineProps {
  alwaysCenter?: boolean
  description?: React.ReactNode
  id?: string
  inverted?: boolean
  overline?: React.ReactNode
  title: React.ReactElement
}

export default function SectionHeadline(props: SectionHeadlineProps) {
  const {
    alwaysCenter = false,
    description,
    id,
    inverted = false,
    overline,
    title,
  } = props
  return (
    <Box sx={{ m: alwaysCenter ? 'auto' : 'none' }}>
      {overline && (
        <Typography
          id={id}
          component='h2'
          variant='body2'
          sx={{
            fontWeight: 'bold',
            color: 'primary.main',
            mb: 1,

            ...(alwaysCenter && {
              textAlign: 'center',
            }),
          }}
        >
          {overline}
        </Typography>
      )}

      {React.cloneElement(title, {
        style: {
          maxWidth: 500,
          ...(alwaysCenter && {
            maxWidth: '100%',
            textAlign: 'center',
          }),
          ...(inverted && {
            color: '#fff',
          }),
        },
      })}

      {description && (
        <Typography
          sx={theme => ({
            mt: 1,
            mb: 3,
            maxWidth: 500,
            ...(inverted
              ? {
                  color: 'grey.400',
                }
              : {
                  color:
                    theme.palette.mode === 'dark' ? 'grey.500' : 'grey.800',
                }),
            ...(alwaysCenter && {
              textAlign: 'center',
              mx: 'auto',
            }),
          })}
        >
          {description}
        </Typography>
      )}
    </Box>
  )
}
