import { Box, Typography } from '@mui/material'
import { McIcon } from '../../../Components'

export const Security = () => (
  <Box className='flex items-center mt-2'>
    <McIcon path='lock' size={0.75} />{' '}
    <Typography variant='body2' className='ml-2'>
      Data is secured with TLS and AES-256 encryption
    </Typography>
  </Box>
)
