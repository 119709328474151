import {
  Badge,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Popover,
  Tooltip,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { MsgStatus } from '../../../../shared/models'
import { useApiCallState } from '../../hooks'
import { useNotifyNav } from '../../hooks/useNotifications'
import { useStores } from '../../models'
import { McIcon } from '../McIcon'

export const Notifications: React.FC = observer(() => {
  const { notificationStore } = useStores()
  const notifyNav = useNotifyNav()
  const { setMsg } = useApiCallState()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const notifications = notificationStore.sortedNotifications

  return (
    <>
      <Tooltip
        title={`${notifications.length === 0 ? 'No ' : ''}Notifications`}
        sx={theme => ({ mr: theme.spacing(1) })}
      >
        <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
          <Badge badgeContent={notifications.length} color='error'>
            <McIcon path='bell' />
          </Badge>
        </IconButton>
      </Tooltip>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper elevation={24}>
          <List
            sx={{
              maxWidth: 320,
              bgcolor: 'background.paper',
              p: 0,
            }}
          >
            {notifications.length > 0 ? (
              <>
                <Button
                  variant='text'
                  sx={{ m: 1 }}
                  onClick={async () => {
                    const { error, errorDetails } =
                      await notificationStore.ackNotifications({
                        ids: notifications.map(n => n.id),
                      })

                    if (error) {
                      console.error(errorDetails)
                      setMsg(error, MsgStatus.ERROR)
                    }

                    setAnchorEl(null)
                  }}
                >
                  Clear All
                </Button>

                {notifications.map((notification, index) => (
                  <React.Fragment key={notification.id}>
                    <ListItemButton
                      onClick={async () => {
                        const { error, errorDetails } =
                          await notificationStore.ackNotifications({
                            ids: [notification.id],
                          })

                        if (error) {
                          console.error(errorDetails)
                          setMsg(error, MsgStatus.ERROR)
                        }

                        notifyNav({
                          screen: notification.screen || undefined,
                          active_id: notification.active_id || undefined,
                        })

                        setAnchorEl(null)
                      }}
                    >
                      <ListItemText
                        primary={notification.title}
                        secondary={notification.body}
                      />
                    </ListItemButton>
                    {index + 1 < notifications.length && (
                      <Divider component='li' />
                    )}
                  </React.Fragment>
                ))}
              </>
            ) : (
              <ListItem>
                <ListItemText primary='No Notifications' />
              </ListItem>
            )}
          </List>
        </Paper>
      </Popover>
    </>
  )
})
