import {
  IconButton,
  InputAdornment,
  Paper,
  Popover,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import { McIcon } from '../McIcon'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        thousandSeparator
        valueIsNumericString
      />
    )
  }
)

type CurrencyTextFieldProps = {
  information?: string
} & TextFieldProps

export const CurrencyTextField = React.forwardRef<
  typeof TextField,
  CurrencyTextFieldProps
>(function CurrencyTextField(props, ref) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  return (
    <>
      <TextField
        {...props}
        inputRef={ref}
        InputProps={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputComponent: NumericFormatCustom as any,
          startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          endAdornment: props.information ? (
            <InputAdornment position='end'>
              <IconButton
                size='small'
                onClick={e => setAnchorEl(e.currentTarget)}
              >
                <McIcon path='information' />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        }}
      />

      {props.information && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Paper elevation={24} sx={{ p: 1, maxWidth: 400, maxHeight: 300 }}>
            <Typography>{props.information}</Typography>
          </Paper>
        </Popover>
      )}
    </>
  )
})
