import { Paper, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Msg } from '../../../../../shared/models'
import { useTheme } from '../../../theme'

interface MessageProps {
  userId: string
  message: Msg
}

export const Message: React.FC<MessageProps> = observer(
  ({ userId, message }) => {
    const [createdTimeAgo, setCreatedTimeAgo] = useState(
      DateTime.fromJSDate(message.createdAt).toRelative()
    )
    const seller = message.user.id !== userId
    const theme = useTheme()

    useEffect(() => {
      // Refresh time ago every 10 seconds
      const intervalId = setInterval(() => {
        setCreatedTimeAgo(DateTime.fromJSDate(message.createdAt).toRelative())
      }, 10 * 1000)

      return () => {
        clearInterval(intervalId)
      }
    }, [message.createdAt])

    const colors = seller
      ? {
          background: theme.palette.background.default,
          text: theme.palette.text.primary,
        }
      : {
          background: theme.palette.primary.main,
          text: theme.palette.primary.contrastText,
        }

    return (
      <Paper
        variant='outlined'
        sx={{
          backgroundColor: colors.background,
          p: 1,
          width: '80%',
          alignSelf: seller ? 'flex-start' : 'flex-end',
          borderRadius: 3,
        }}
      >
        <Typography color={colors.text}>{message.text}</Typography>
        <Typography variant='caption' color={colors.text}>
          {createdTimeAgo}
        </Typography>
      </Paper>
    )
  }
)
