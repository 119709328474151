import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { withSetPropAction } from "../withSetPropAction"

export const ProfileModel = types
  .model("Profile")
  .props({
    user_id: types.identifier,
    name: types.maybeNull(types.string),
    pic: types.maybeNull(types.string),
    agent_license: types.maybeNull(types.number),
  })
  .actions(withSetPropAction)

export interface Profile extends Instance<typeof ProfileModel> {}
export interface ProfileSnapshot extends SnapshotOut<typeof ProfileModel> {}
