const earnestMoney = `
Earnest money is a good-faith deposit to show the seller you are a serious buyer. 
The money is deposited once the offer is accepted and is kept in an escrow account 
usually with your attorney. A typical Earnest money deposit is 1% to 3% of the 
purchase price, is refundable during the due dilligence period and is credited toward 
the home purchase after closing`

const dueDilligenceFee = `
Due Dilligence Fee is not mandatory but it is to compensate the seller for the period for
which he or she removed the home from the market. The money is paid directly to the seller. 
A typical Due Dilligence money deposit is 1% to 3% of the purchase price, is not refundable 
and is credited toward the home purchase after closing`

export const info = { earnestMoney, dueDilligenceFee }
