import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CommissionType } from '../../../../../shared/models'
import { formatCurrency } from '../../../../../shared/utils'
import { info } from '../../../../data'
import { CurrencyTextField, McIcon } from '../../../Components'
import { useApiCallState } from '../../../hooks'
import { useStores } from '../../../models'
import { useTheme } from '../../../theme'
import { FundsCard } from '../../Account/FundsCard'
import { DocCard } from '../Docs/DocCard'

export interface IFormInput {
  price: string
  agentCommission: string
  earnest: string
  dueDilligence: string
  agent_license?: string
}

interface OfferFormProps {
  listing_id: string
  exit: () => void
  defaultVals: IFormInput
  counteredOfferId?: number
  commission?: string
  commission_type?: string
}

export const OfferForm: React.FC<OfferFormProps> = observer(
  ({ listing_id, exit, defaultVals, commission, commission_type }) => {
    const { accountStore, offerStore, listingStore, fileStore } = useStores()
    const { loading, setLoading, setMsg } = useApiCallState()
    const [usingAgent, setUsingAgent] = useState(true)
    const [currentPrice, setCurrentPrice] = useState(defaultVals.price)
    const [isAgent, setIsAgent] = useState(!!defaultVals.agent_license)
    const [offerFile, setOfferFile] = useState<File | null>(null)
    const theme = useTheme()
    const {
      control,
      handleSubmit,
      formState: { isValid },
    } = useForm<IFormInput>({
      mode: 'onChange',
      reValidateMode: 'onChange',
    })
    const latestOffer = offerStore.latestListingOffer(listing_id)
    const userId = accountStore.user.userId
    const dollarCommission = parseFloat(defaultVals.agentCommission)
    const hasCommissionOffer = !isNaN(dollarCommission) && dollarCommission > 0
    const listing = listingStore.listingById(listing_id)

    if (!accountStore.user.loggedIn || !userId) {
      return (
        <Box>
          <Alert severity='info' variant='filled' sx={{ mb: theme.spacing(1) }}>
            Please login to securely submit an offer
          </Alert>
        </Box>
      )
    }

    const onSubmit = async (data: IFormInput) => {
      if (offerFile) {
        setLoading(true)

        if (data.agent_license) {
          accountStore.user.updateProfile({
            agent_license: parseInt(data.agent_license.trim()),
          })
        }

        const { error, errorDetails } = await offerStore.submitOffer({
          listing_id,
          doc: offerFile,
          price: parseFloat(data.price),
          earnest: parseFloat(data.earnest),
          dueDilligence: parseFloat(data.dueDilligence),
          agentCommission: usingAgent
            ? hasCommissionOffer
              ? dollarCommission
              : parseFloat(data.agentCommission)
            : 0,
        })

        await Promise.all([
          offerStore.fetchOffers({ listing_id }),
          fileStore.fetchFiles({ listing_id }),
        ])

        setLoading(false)

        if (error) {
          console.error(error)
          console.error(errorDetails)
          setMsg(error, 'error')
        } else {
          setMsg('Offer Submitted', 'success')
        }

        exit()
      }
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card variant='outlined'>
          <CardContent>
            <FundsCard sx={{ minWidth: 300, mb: theme.spacing(2) }} />

            <Box
              sx={{
                mb: theme.spacing(usingAgent ? 0 : 1),
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography>Are you using a real estate agent?</Typography>
              <Checkbox
                checked={usingAgent}
                onChange={event => setUsingAgent(event.target.checked)}
              />
            </Box>

            {usingAgent && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: theme.spacing(2),
                  }}
                >
                  <Typography>
                    Are you a real estate agent representing a buyer?
                  </Typography>
                  <Checkbox
                    checked={isAgent}
                    onChange={event => setIsAgent(event.target.checked)}
                  />
                </Box>

                {isAgent && (
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        required
                        label='License Number'
                        id='agent-license'
                        variant='outlined'
                        size='small'
                        sx={{ width: '100%', mb: theme.spacing(2) }}
                        error={!!error}
                        helperText={
                          error?.type === 'maxLength'
                            ? 'Max Length 20'
                            : error?.message
                        }
                      />
                    )}
                    name='agent_license'
                    control={control}
                    defaultValue={defaultVals.agent_license}
                    rules={{ required: 'Required', maxLength: 20 }}
                  />
                )}
              </>
            )}

            {usingAgent && !hasCommissionOffer && (
              <Alert
                severity='info'
                variant='filled'
                sx={{ mb: theme.spacing(3) }}
              >
                The listed price does not include Buyer Agent Commission. Please
                indicate the amount you have agreed to pay your agent if you
                would prefer to pay it out of the purchase price.
              </Alert>
            )}

            <Grid container spacing={2}>
              <Grid size={{ xs: usingAgent ? 6 : 12 }}>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <CurrencyTextField
                      {...field}
                      sx={{ width: '100%' }}
                      label='Purchase Price'
                      name='numberformat'
                      id='purchase-price'
                      variant='outlined'
                      required
                      error={!!error}
                      helperText={
                        error?.type === 'maxLength'
                          ? 'Max Length 10'
                          : error?.message
                      }
                      onChange={e => {
                        setCurrentPrice(e.target.value)
                        field.onChange(e)
                      }}
                    />
                  )}
                  name='price'
                  control={control}
                  defaultValue={defaultVals.price}
                  rules={{ required: 'Required', maxLength: 10 }}
                />
              </Grid>

              {usingAgent && (
                <Grid size={{ xs: 6 }}>
                  {hasCommissionOffer ? (
                    <Paper
                      sx={{
                        pt: 0.75,
                        pb: 0.75,
                        pl: 1,
                        pr: 1,
                      }}
                      elevation={1}
                    >
                      <Typography sx={{ mr: 2 }} variant='body2'>
                        Offered Commission
                      </Typography>

                      {commission_type === CommissionType.percent && listing ? (
                        <Typography variant='body1'>
                          {formatCurrency(
                            listing.commissionAmount(parseFloat(currentPrice))
                          )}
                          {` (${commission}%)`}
                        </Typography>
                      ) : (
                        <Typography variant='body1'>
                          {formatCurrency(dollarCommission)}
                        </Typography>
                      )}
                    </Paper>
                  ) : (
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <CurrencyTextField
                          {...field}
                          sx={{ width: '100%' }}
                          label='Agent Commission'
                          name='numberformat'
                          id='agent-commission'
                          variant='outlined'
                          required
                          error={!!error}
                          helperText={
                            error?.type === 'maxLength'
                              ? 'Max Length 10'
                              : error?.message
                          }
                        />
                      )}
                      name='agentCommission'
                      control={control}
                      defaultValue={defaultVals.agentCommission}
                      rules={{ required: 'Required', maxLength: 10 }}
                    />
                  )}
                </Grid>
              )}

              <Grid size={{ xs: 6 }}>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <CurrencyTextField
                      {...field}
                      sx={{ width: '100%' }}
                      label='Earnest Money'
                      name='numberformat'
                      id='earnest-money'
                      variant='outlined'
                      required
                      error={!!error}
                      helperText={
                        error?.type === 'maxLength'
                          ? 'Max Length 10'
                          : error?.message
                      }
                      information={info.earnestMoney}
                    />
                  )}
                  name='earnest'
                  control={control}
                  defaultValue={defaultVals.earnest}
                  rules={{ required: 'Required', maxLength: 10 }}
                />
              </Grid>

              <Grid size={{ xs: 6 }}>
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <CurrencyTextField
                      {...field}
                      sx={{ width: '100%' }}
                      label='Due Dilligence Fee'
                      name='numberformat'
                      id='dd-feww'
                      variant='outlined'
                      required
                      error={!!error}
                      helperText={
                        error?.type === 'maxLength'
                          ? 'Max Length 10'
                          : error?.message
                      }
                      information={info.dueDilligenceFee}
                    />
                  )}
                  name='dueDilligence'
                  control={control}
                  defaultValue={defaultVals.dueDilligence}
                  rules={{ required: 'Required', maxLength: 10 }}
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: theme.spacing(3) }}>
              {offerFile ? (
                <DocCard
                  sx={{ minWidth: 300 }}
                  offerId={latestOffer?.id}
                  offerFile={offerFile}
                  setOfferFile={setOfferFile}
                />
              ) : (
                <>
                  <Button
                    component='label'
                    color='secondary'
                    variant='contained'
                    startIcon={<McIcon path='download-circle' />}
                    sx={{ width: '100%', mb: theme.spacing(2) }}
                    onClick={async () => {
                      const { error, errorDetails, url } =
                        await offerStore.getBlankContractUrl()
                      if (error) {
                        console.error(errorDetails ?? error)
                        setMsg(error, 'error')
                      } else if (url) {
                        window.open(url, '_blank')
                      }
                    }}
                  >
                    Download Blank Offer Contract
                  </Button>
                  <Button
                    component='label'
                    color='primary'
                    variant='contained'
                    startIcon={<McIcon path='cloud-upload' />}
                    sx={{ width: '100%' }}
                  >
                    Upload Completed Offer Contract
                    <VisuallyHiddenInput
                      type='file'
                      accept='.pdf'
                      multiple={false}
                      onChange={event => {
                        if (
                          event.target.files &&
                          event.target.files?.length > 0
                        ) {
                          const doc = event.target.files[0]
                          if (doc.type === 'application/pdf') {
                            setOfferFile(doc)
                          }
                        }
                      }}
                    />
                  </Button>
                </>
              )}
            </Box>
          </CardContent>

          <Divider />

          <CardActions
            sx={{ alignItems: 'stretch', justifyContent: 'flex-end' }}
          >
            <Stack direction='row' spacing={2} sx={{ p: theme.spacing(1) }}>
              <Button
                variant='contained'
                color='error'
                onClick={() => exit()}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                color='success'
                startIcon={<McIcon path='cash-fast' />}
                disabled={!isValid || !offerFile || loading}
                type='submit'
              >
                Submit Offer
                {loading && (
                  <CircularProgress size={24} sx={{ ml: theme.spacing(1) }} />
                )}
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </form>
    )
  }
)

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})
