/**
 * Format number as currency
 */
export function formatCurrency(
  value: string | number,
  includeCents: boolean = false,
  currency: string = 'USD'
) {
  if (typeof value === 'string') {
    value = parseFloat(value)
  }

  if (isNaN(value)) {
    value = 0
  }

  const config: Intl.NumberFormatOptions = {
    style: 'currency',
    currency,
  }

  if (!includeCents) {
    config.minimumFractionDigits = 0
  }

  const client = new Intl.NumberFormat('en-US', config)
  return client.format(value)
}
