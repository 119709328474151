import { DateTime } from "luxon"
import { useEffect, useState } from "react"
import { io, Socket } from "socket.io-client"
import { ChatStore, MsgModel, NotificationModel, NotificationStore } from "../models"

/**
 * Chat and Notification realtime updates
 */
export function useRealtime({
  host,
  chatStore,
  notificationStore,
  getIdToken,
  loggedIn,
}: {
  host: string
  chatStore: ChatStore
  notificationStore: NotificationStore
  getIdToken: (refresh?: boolean) => Promise<string | null>
  loggedIn: boolean
}) {
  const [websocket, setWebSocket] = useState<Socket | null>(null)

  useEffect(() => {
    // Fetch chat and notification messages every minute to make sure we
    // dont miss any if there is an issue with the socket connection
    const interval = setInterval(() => {
      chatStore.fetchMessages({ limit: 20 })
      notificationStore.fetchNotifications({ limit: 20 })
    }, 60 * 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    async function socketConnect() {
      const token = await getIdToken()
      if (!token) {
        console.log("RT: Unable to get user token")
        return
      }

      const socket = io(host, {
        withCredentials: true,
        auth: { token },
      })
      setWebSocket(socket)

      socket.on("connect", () => {
        console.log(`Socket Connected: ${socket.id}`)

        socket.on("msg", async (payload) => {
          if (payload.collection === "chats" && payload.op === "insert") {
            const doc = payload.doc

            const { profiles } = await chatStore.getProfiles({
              userIds: [doc.user_id, doc.buyer_user_id],
            })

            chatStore.setProp("messages", [
              ...chatStore.messages.filter((r) => r.id !== doc._id),
              MsgModel.create({
                id: doc._id,
                type: doc.type,
                createdAt: DateTime.fromISO(doc.created_at).toJSDate(),
                text: doc.text,
                user: {
                  id: doc.user_id,
                  name: profiles[doc.user_id].name,
                  avatar: profiles[doc.user_id].pic,
                },
                listing_id: doc.listing_id,
                buyer_user: {
                  id: doc.buyer_user_id,
                  name: profiles[doc.buyer_user_id].name,
                  avatar: profiles[doc.buyer_user_id].pic,
                },
              }),
            ])
          } else if (payload.collection === "notifications") {
            const doc = payload.doc

            notificationStore.setProp("notifications", [
              ...notificationStore.notifications.filter((n) => n.id !== doc._id),
              NotificationModel.create({
                ...doc,
                id: doc._id,
                realtime: true,
                created_at: DateTime.fromISO(doc.created_at, { zone: "utc" }).toJSDate(),
                updated_at: doc.updated_at
                  ? DateTime.fromISO(doc.updated_at, { zone: "utc" }).toJSDate()
                  : null,
              }),
            ])
          }
        })
      })

      socket.on("disconnect", async () => {
        console.log(`Socket disconnected: ${socket.id}`)
        if (getIdToken) {
          const token = await getIdToken()
          if (token) {
            // @ts-expect-error sending token
            socket.auth.token = token
          }
        }
      })

      socket.on("reconnect_attempt", () => {
        console.log(`Socket reconnecting`)
      })

      socket.on("connect_error", async (error) => {
        if (getIdToken) {
          const token = await getIdToken()
          if (token) {
            // @ts-expect-error sending token
            socket.auth.token = token
          }
        }

        console.log(`Socket connection error`)
        console.error(error)

        if (socket.active) {
          console.log("Socket auto reconnect")
        } else {
          setTimeout(() => {
            console.log("Socket manual reconnect")
            socket.connect()
          }, 1000)
        }
      })
    }

    if (loggedIn && !websocket?.active) {
      socketConnect()
    }
  }, [loggedIn])

  return null
}
