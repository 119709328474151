import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { withSetPropAction } from "../withSetPropAction"

export const FilePrevVersionModel = types
  .model("FilePrevVersion")
  .props({
    user_id: types.string,
    updated_at: types.Date,
    path: types.string,
    mimetype: types.string,
    size: types.number,
  })
  .actions(withSetPropAction)

export interface FilePrevVersion extends Instance<typeof FilePrevVersionModel> {}
export interface FilePrevVersionSnapshotOut extends SnapshotOut<typeof FilePrevVersionModel> {}
export interface FilePrevVersionSnapshotIn extends SnapshotIn<typeof FilePrevVersionModel> {}
