import { Box, Toolbar } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Outlet } from 'react-router-dom'
import { useBrowserNotify } from '../../hooks'
import { Header } from '../Header'

export const HeaderLayout = observer(() => {
  useBrowserNotify()

  return (
    <>
      <Header />
      <Box component='main'>
        <Toolbar />
        <Outlet />
      </Box>
    </>
  )
})
