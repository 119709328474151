/**
 * These are configuration settings for the dev environment.
 */
export default {
  prod: false,
  sentryActive: false,
  analyticsActive: false,
  redirectUrl: import.meta.env.VITE_REDIRECT_URL || 'http://localhost:3000',
  apiUrl: 'http://localhost:8000',
  rtHost: 'ws://localhost:8080',
}
