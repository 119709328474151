import { InfoWindow, Marker, useMarkerRef } from '@vis.gl/react-google-maps'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Listing } from '../../../../shared/models'
import { getVanityAddress, mapLink } from '../../../../shared/utils'
import { ListingCard } from '../../Pages/Listings/ListingCard'
import { useStores } from '../../models'

import { useMarkerImage } from './useMarkerImage'

export const MapMarker = observer(
  ({
    listing,
    position,
    listingDetailsMap,
    isFullScreen = false,
  }: {
    listing: Listing
    position?: google.maps.LatLngLiteral | google.maps.LatLng | null | undefined
    listingDetailsMap: boolean
    isFullScreen?: boolean
  }) => {
    const { listingStore } = useStores()
    const navigate = useNavigate()
    const [markerRef, marker] = useMarkerRef()
    const markerImage = useMarkerImage()
    const isActive = listing.id === listingStore?.activeListing?.id
    const vanityAddress = getVanityAddress(listing.address)

    // clicking the marker will toggle the infowindow
    const handleMarkerClick = useCallback(() => {
      if (listingDetailsMap) {
        if (listing.longitude && listing.latitude) {
          window.open(
            mapLink({
              latitude: listing.latitude,
              longitude: listing.longitude,
              place_id: listing.place_id,
            }),
            '_blank',
            'noreferrer'
          )
        }
      } else {
        if (isFullScreen) {
          listingStore.setProp('activeListingId', listing.id)
        } else {
          navigate(`/listings/${vanityAddress}/${listing.id}`)
        }
      }
    }, [
      listingStore,
      navigate,
      listingDetailsMap,
      listing.longitude,
      listing.latitude,
      listing.place_id,
      vanityAddress,
      isFullScreen,
      listing.id,
    ])

    return (
      <>
        <Marker
          ref={markerRef}
          position={position}
          onClick={handleMarkerClick}
          onMouseOver={() =>
            !listingDetailsMap &&
            listingStore.setProp('activeListingId', listing.id)
          }
          icon={markerImage}
        />

        {!listingDetailsMap && isActive && (
          <InfoWindow
            anchor={marker}
            onClose={() => listingStore.setProp('activeListingId', null)}
          >
            <ListingCard
              listing={listing}
              mediaHeight={140}
              clickable={true}
              raiseOnActive={false}
            />
          </InfoWindow>
        )}
      </>
    )
  }
)
