import {
  ArticleRounded,
  EditCalendarRounded,
  FolderCopyRounded,
  QuestionAnswerRounded,
} from '@mui/icons-material'
import { Box } from '@mui/material'
import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'
import GradientText from './GradientText'
import { InfoCard } from './InfoCard'
import SectionHeadline from './SectionHeadline'

const content = [
  {
    icon: <EditCalendarRounded fontSize='small' color='primary' />,
    title: 'Effortless Home Tour Scheduling',
    description: `Book, reschedule, or cancel tours with a single click. Choose times that fit your schedule, not the listing agent's. Say goodbye to phone tag and hello to flexibility.`,
  },
  {
    icon: <ArticleRounded fontSize='small' color='primary' />,
    title: 'Streamlined Offer Submission',
    description:
      'Submit offers directly from the listing page using our standard contract form. Track offer status in real-time, eliminating uncertainty and saving you time.',
  },
  {
    icon: <FolderCopyRounded fontSize='small' color='primary' />,
    title: 'Hassle-Free Document Handling',
    description:
      'Upload required documents right on the listing page when needed. Our smart system ensures your files reach the right person, every time. No more guesswork or lost emails.',
  },
  {
    icon: <QuestionAnswerRounded fontSize='small' color='primary' />,
    title: 'Direct Seller Communication',
    description:
      'Got questions? Connect with sellers instantly through our in-app chat. Receive email notifications for responses, ensuring you never miss important information about your potential new home.',
  },
]

export function ValueProps() {
  return (
    <Box component='section'>
      <SectionHeadline
        overline='Why Choose Homy?'
        title={
          <Typography variant='h3' sx={{ mt: 1, mb: { xs: 2, sm: 4 } }}>
            A <GradientText>Seamless, Guided</GradientText> Home Buying
            Experience
          </Typography>
        }
      />

      <Grid container spacing={3}>
        {content.map(({ icon, title, description }) => (
          <Grid key={title} size={{ xs: 12, sm: 6, lg: 3 }}>
            <InfoCard title={title} icon={icon} description={description} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
