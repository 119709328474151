import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useStores } from '../../models'
import { useTheme } from '../../theme'
import { FundsCard } from './FundsCard'
import { FundsUpload } from './FundsUpload'
import { Profile } from './Profile'

export const Account: React.FC = observer(() => {
  const { accountStore } = useStores()
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const proofOfFunds = accountStore.user.proofOfFunds
  const loggedIn = accountStore.user.loggedIn

  useEffect(() => {
    document.title = 'Account'
  }, [])

  useEffect(() => {
    async function getProfile() {
      setLoading(true)
      await accountStore.user.fetchProfile()
      setLoading(false)
    }

    if (loggedIn && accountStore.user) {
      getProfile()
    }
  }, [accountStore.user, loggedIn])

  if (!loggedIn) {
    return (
      <Container sx={{ mt: theme.spacing(2) }}>
        <Alert severity='info' variant='filled' sx={{ mb: theme.spacing(1) }}>
          Login to manage your account.
        </Alert>
      </Container>
    )
  }

  return (
    <Container sx={{ mt: theme.spacing(2) }} maxWidth='md'>
      <div className='flex justify-center items-center'>
        <Typography variant='h6'>{accountStore.user.email}</Typography>
      </div>

      <Box sx={{ mt: 3, mb: 12 }}>
        <Typography variant='h5'>Public Profile</Typography>
        {loading ? (
          <CircularProgress size={30} sx={{ mr: theme.spacing(1) }} />
        ) : (
          <Profile />
        )}
      </Box>

      <Box sx={{ mt: 3, mb: 3 }}>
        <Typography variant='h5'>Documents</Typography>
        {proofOfFunds === null && (
          <CircularProgress size={30} sx={{ mr: theme.spacing(1) }} />
        )}
        {proofOfFunds === true && <FundsCard />}
        <Box sx={{ mt: 2 }}>{proofOfFunds === false && <FundsUpload />}</Box>
      </Box>
    </Container>
  )
})
