import { Alert, Container } from '@mui/material'
import { useRouteError } from 'react-router-dom'
import { Header } from '../../Components'
import { useTheme } from '../../theme'

interface Error {
  status?: number
  statusText?: string
  message?: string
}

export function ErrorPage() {
  const error = useRouteError() as Error
  const theme = useTheme()

  return (
    <>
      <Header />
      <Container sx={{ mt: theme.spacing(2) }}>
        <Alert variant='filled' severity='error'>
          {error.status} {error.statusText || error.message}
        </Alert>
      </Container>
    </>
  )
}
