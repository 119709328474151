import { Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { withSetPropAction } from "../withSetPropAction"

export const NotificationModel = types
  .model("Notification")
  .props({
    id: types.identifier,
    created_at: types.Date,
    updated_at: types.maybeNull(types.Date),
    title: types.string,
    body: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    screen: types.maybeNull(types.string),
    active_id: types.maybeNull(types.string),
    secondary_id: types.maybeNull(types.string),
    ack: false,
    realtime: false,
  })
  .actions(withSetPropAction)

export interface Notification extends Instance<typeof NotificationModel> {}
export interface NotificationSnapshotOut extends SnapshotOut<typeof NotificationModel> {}
export interface NotificationSnapshotIn extends SnapshotIn<typeof NotificationModel> {}
