import { useEffect } from 'react'
import { useStores } from '../models'

export function useCheckFunds(userId?: string) {
  const { accountStore } = useStores()

  useEffect(() => {
    if (accountStore.loaded) {
      if (userId) {
        accountStore.user.checkFunds({})
      } else {
        accountStore.user.setProp('proofOfFunds', false)
      }
    }
  }, [accountStore.loaded, accountStore.user, userId])

  return null
}
