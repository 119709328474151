import { Box, Chip, SxProps, Theme, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { listingPhases } from '../../../../shared/data'
import { CommissionType, Listing } from '../../../../shared/models'
import { formatCurrency } from '../../../../shared/utils'
import { McIcon } from '../../Components'
import { useListingAttributes } from './useListingAttributes'

interface AdditionalDetailsProps {
  listing: Listing
}

export const AdditionalDetails: React.FC<AdditionalDetailsProps> = observer(
  ({ listing }) => {
    const attributes = useListingAttributes(listing)
    return (
      <Box>
        <Typography variant='h5' component='div' sx={{ mb: 1 }}>
          Additional Details
        </Typography>

        <Box sx={$attributeContainer}>
          {attributes.additional.map(
            attr =>
              attr.title && (
                <Chip
                  variant='outlined'
                  key={attr.title}
                  icon={attr.icon}
                  label={attr.title}
                  sx={{ mr: 1, mt: 1 }}
                />
              )
          )}
        </Box>

        {listing.commissionAmount() ? (
          <Box sx={{ mb: 2 }}>
            <Typography variant='body1' component='div'>
              Buyer Agent Commission
            </Typography>
            <Chip
              variant='outlined'
              icon={<McIcon path='account-cash' />}
              label={
                listing.commission_type === CommissionType.percent
                  ? `${formatCurrency(listing.commissionAmount())}  (${listing.commission}%)`
                  : formatCurrency(listing.commissionAmount())
              }
              sx={{ mr: 1, mt: 1 }}
            />
          </Box>
        ) : null}

        <Box>
          <Grid container spacing={2}>
            {Object.entries(listingPhases).map(([id, data]) => {
              const entries: { value: string; icon: string }[] = []
              if (id === 'warranty') {
                if (!(listing.warranty_provider || listing.warranty_type)) {
                  return null
                }
                if (listing.warranty_type) {
                  entries.push({
                    value: listing.warranty_type,
                    icon: 'certificate',
                  })
                }
                if (listing.warranty_provider) {
                  entries.push({
                    value: listing.warranty_provider,
                    icon: 'card-account-details',
                  })
                }
              } else {
                // @ts-expect-error Mapping exists
                const activeValue = listing[id]
                if (!activeValue) {
                  return null
                }

                const values: string[] = Array.isArray(activeValue)
                  ? activeValue
                  : [activeValue]

                for (const value of values) {
                  const activeEntry = data.items.find(i => i.id === value)
                  if (activeEntry) {
                    entries.push({
                      value: activeEntry.label,
                      icon: activeEntry.icon,
                    })
                  }
                }
              }

              return (
                entries.length > 0 && (
                  <Grid key={id} size={{ xs: 12, md: 6 }}>
                    <Typography variant='body1' component='div'>
                      {data.title}
                    </Typography>

                    <Box sx={$attributeContainer}>
                      {entries.map(entry => (
                        <Chip
                          variant='outlined'
                          key={entry.value}
                          // @ts-expect-error Mapping exists
                          icon={<McIcon path={entry.icon} size={1} />}
                          label={entry.value}
                          sx={{ mr: 1, mt: 1 }}
                        />
                      ))}
                    </Box>
                  </Grid>
                )
              )
            })}
          </Grid>
        </Box>
      </Box>
    )
  }
)

const $attributeContainer: SxProps<Theme> = {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  mb: 2,
}
