import { Alert, Box, Button, Card, CardContent } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { McIcon } from '../../../Components'
import { useStores } from '../../../models'
import { useTheme } from '../../../theme'
import { FundsUpload } from '../../Account'
import { FundsCard } from '../../Account/FundsCard'
import { Docs } from '../Docs/Docs'
import { OfferDetails } from './OfferDetails'
import { OfferForm } from './OfferForm'
import { TourDetails } from './TourDetails'
import { TourScheduleCard } from './TourScheduleCard'

interface ActionsProps {
  listing_id: string
  price?: number
  listingAcceptedOffer: string | null
}

export const Actions: React.FC<ActionsProps> = observer(
  ({ listing_id, price, listingAcceptedOffer }) => {
    const { accountStore, offerStore, tourStore, listingStore, fileStore } =
      useStores()
    const [newOffer, setNewOffer] = useState(false)
    const [newTour, setNewTour] = useState(false)
    const [activeAction, setActiveAction] = useState<'offer' | 'tour' | null>(
      null
    )
    const theme = useTheme()
    const listing = listing_id ? listingStore.listingById(listing_id) : null

    if (!listing) {
      return null
    }

    const dd_earnest = price ? Math.round(price * 0.02).toString() : undefined
    const [defaultVals, setDefaultVals] = useState({
      price: price ? price.toString() : '',
      dueDilligence: dd_earnest || '',
      earnest: dd_earnest || '',
      agentCommission: listing?.commissionAmount()
        ? listing?.commissionAmount().toString()
        : '0',
      agent_license: accountStore.user.profile?.agent_license?.toString() ?? '',
    })

    const proofOfFunds = accountStore.user.proofOfFunds
    const latestOffer = offerStore.latestListingOffer(listing_id)
    const latestTour = tourStore.latestListingTour(listing_id)
    const userId = accountStore.user.userId
    const accepted_offerDetails = listingAcceptedOffer
      ? offerStore.offerById(listingAcceptedOffer)
      : null
    const docs = fileStore.listingFiles({ listing_id })

    if (listingAcceptedOffer && !accepted_offerDetails) {
      return (
        <Box>
          <Alert severity='info' variant='filled' sx={{ mb: theme.spacing(1) }}>
            An offer has already been accepted for this home and it is under
            contract
          </Alert>
        </Box>
      )
    }

    if (!accountStore.user.loggedIn || !userId) {
      return (
        <Box>
          <Alert severity='info' variant='filled' sx={{ mb: theme.spacing(1) }}>
            Please login to securely submit an offer, request a tour, or ask the
            seller a question
          </Alert>
        </Box>
      )
    }

    if (accepted_offerDetails) {
      return (
        <>
          <OfferDetails offerId={accepted_offerDetails.id} />

          <Box sx={{ mt: 2 }}>
            <Docs listing={listing} shared />
          </Box>

          {docs.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Docs listing={listing} />
            </Box>
          )}
        </>
      )
    }

    if (latestOffer && !newOffer) {
      return (
        <OfferDetails
          offerId={latestOffer.id}
          newOffer={counter => {
            if (counter) {
              setDefaultVals({
                price: counter.price.toString(),
                dueDilligence: counter.due_dilligence.toString(),
                earnest: counter.earnest.toString(),
                agentCommission:
                  latestOffer.agent_commission?.toString() || '0',
                agent_license: '',
              })
            }

            setActiveAction('offer')
            setNewOffer(true)
          }}
          newTour={() => {
            setActiveAction('tour')
            setNewTour(true)
            setNewOffer(true)
          }}
        />
      )
    }

    if (proofOfFunds === false) {
      return (
        <Card variant='outlined'>
          <CardContent>
            <FundsUpload />
          </CardContent>
        </Card>
      )
    }

    if (activeAction !== 'offer' && latestTour && !newTour) {
      return (
        <TourDetails
          tour={latestTour}
          address={listing ? listing.address : ''}
          newTour={() => {
            setActiveAction('tour')
            setNewTour(true)
          }}
          newOffer={() => {
            setActiveAction('offer')
            setNewTour(true)
          }}
        />
      )
    }

    if (proofOfFunds === true) {
      return activeAction === 'tour' ? (
        <TourScheduleCard
          listing_id={listing_id}
          exit={requested => {
            setActiveAction(null)
            if (requested && newTour) {
              setNewTour(false)
            }
          }}
        />
      ) : activeAction === 'offer' ? (
        <OfferForm
          listing_id={listing_id}
          defaultVals={defaultVals}
          commission={listing?.commission?.toString() || undefined}
          commission_type={listing?.commission_type}
          exit={() => {
            setNewOffer(false)
            setActiveAction(null)
          }}
        />
      ) : (
        <Card variant='outlined'>
          <CardContent>
            <FundsCard sx={{ minWidth: 300, mb: theme.spacing(2) }} />

            <Button
              color='primary'
              variant='contained'
              startIcon={<McIcon path='calendar-plus' />}
              sx={{ width: '100%', mb: theme.spacing(1) }}
              onClick={() => setActiveAction('tour')}
            >
              Request a Tour
            </Button>

            <Button
              color='success'
              variant='contained'
              startIcon={<McIcon path='cash-fast' />}
              sx={{ width: '100%' }}
              onClick={() => setActiveAction('offer')}
            >
              Submit an Offer
            </Button>
          </CardContent>
        </Card>
      )
    }

    return null
  }
)
